#configuratorModal {
    text-align: center;

    div {
        margin: 15px;

        &.h3 {
            color: $color-brilliant-red;
        }

        &.h4 {
            color: $color-navy-blue;
        }
    }


    p {
        text-align: justify;
        color: $dark-gray;
        font-family: $body-font-family;
        line-height: 1.8;
        margin-bottom: 10px;
    }

    a {
        margin-top: 20px;
    }

}