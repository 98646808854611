.open-text {

  /*&.u-round-section,
  section.masthead + & {
      background-color: $color-white;
      margin: 0 auto;
      max-width: 1220px;
      width: 90%;

      @include breakpoint(medium) { // was mappy-bp(tablet-vert)
        border-radius: 8px;
        margin-top: -200px; // Was -120 but that leaves a huge gap.
      }
  }*/


  .main-container {
    max-width: rem-calc(780px);
  }

  &.wide {
    .main-container {
      max-width: rem-calc(1220px);
    }
  }


  padding: 45px 0;

  @include breakpoint(tablet-vert) {
    padding: 60px 0;
  }

  // Replaced with Foundation container which uses rems
  /*.container {
    max-width: 780px;
  }*/

  &__heading {
    @include to-em(40px);
    color: $color-navy-blue;
    text-align: center;

    @include breakpoint(tablet-vert) {
      margin-bottom: 40px;
    }
  }

  &__copy {

    p {
      line-height: 1.75;
    }

    a {
      color: $color-navy-blue;
      font-weight: bold;
    }

    // Whenever we use videos we ensure that they are appropriately spaced, in case MediaElementJS doesn't fire for some reason.
    .wp-video {
      position: relative;
      width: 100% !important;
      padding-bottom: 56.25%;

      .wp-video-shortcode {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}



/*.hope-mission {
  padding: 45px 0;

  @include breakpoint(tablet-vert) {
    padding: 90px 0;
  }

  .container {
    max-width: 780px;
  }

  &__heading {
    @include to-em(40px);
    color: $color-navy-blue;
    text-align: center;

    @include breakpoint(tablet-vert) {
      margin-bottom: 40px;
    }
  }

  p {
    line-height: 1.75;

    a {
      color: $color-navy-blue;
      font-weight: bold;
    }
  }
}*/
