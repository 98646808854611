.support-faq {
  margin-bottom: 32px;

  &__header {
    @include breakpoint(tablet-vert) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
  }

  &__heading {
    margin: 0;
    flex: 1 0 auto;
  }

  &__btn-wrapper {
    margin-bottom: 20px;
    position: relative;

    @include breakpoint(large) {
      display: flex;
      align-items: flex-end;
    }

    .c-accordion__expand {
      top: 60px;
    }
  }

  &__heading {
    @include font-sans(bold);
    @include to-em(30px);
    color: $color-navy-blue;
  }

  &__view-all {
    margin: 20px 0 40px;
    @include to-em(12px);
    text-transform: uppercase;
    width: 190px;

    @include breakpoint(tablet-vert) {
      margin: 0;
    }
  }

  &__item-content {
    &.c-accordion__content {
      @include breakpoint(tablet-vert) {
        padding: 0;
        margin-left: 0;
        padding-top: 25px;
        margin-right: 40px;
        position: relative;
        top: 0;

      }

    }
  }

  &__item {
    display: block;

    > .c-accordion__content {
      display: none;
      @include breakpoint(tablet-vert) {
        padding: 0;
        margin-left: 0;
        padding-top: 25px;
        margin-right: 40px;
        position: relative;
        top: 0;

      }

      p {
        margin-bottom: 10px;
      }

      &--table {
        padding-top: 40px;

        @include breakpoint(tablet-vert) {
          margin-right: 0;
          margin-left: 20px;
        }
      }
    }
  }
}


.c-accordion__nav.support-faq__btn-nav {
  border: 0;
  background: transparent;

  padding: 40px 0 25px;

  .c-accordion__btn {
    transition: ease all 0.3s;
    margin-right: 10px;
    margin-left: 0;


    font-size: rem-calc(14px);
    padding: 15px 25px;
    color: $color-navy-blue;
    font-weight: 400;

    &:hover {
      background-color: rgba($color-navy-blue, .1);
    }

    &.is-active, &.active {
      background-color: $color-navy-blue;
      color: $color-white;
    }
  }
}