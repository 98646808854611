.search-bar {
  &__bg {
    background-color: rgba($color-black, 0.2);
    bottom: 0;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 98;

    .search-bar--inview & {
      display: block;
    }
  }

  &--inview {
    overflow: hidden;
  }

  &--modal {
    bottom: 0;
    display: none;
    height: 100%;
    left: 0;
    padding-top: 155px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 99;

    .search-bar__wrapper {
      background-color: $color-off-white;
      border-radius: 8px;
      margin: 0 auto;
      max-width: 978px;
      position: relative;
      width: 90%;

      @include breakpoint(tablet-vert) {
        width: 68%;
      }
    }

    .search-bar__form-submit {
      @include to-em(13px);
      width: 100%;

      @include breakpoint(tablet-vert) {
        width: 207px;
      }
    }

    .search-bar--inview & {
      display: block;
    }

    .search-bar__header {
      padding: 18px 18px 15px;

      @include breakpoint(tablet-vert) {
        padding: 30px 30px 15px;
      }
    }

    .search-bar__form-input {
      text-align: center;

      @include breakpoint(tablet-vert) {
        text-align: left;
      }
    }
  }

  &__form {
    background-color: $color-white;
    border: 1px solid $color-border-grey;
    padding: 12px;

    @include breakpoint(tablet-vert) {
      display: flex;
      padding: 4px 12px 4px 32px;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__form-input {
    appearance: none;
    border: 0;

    &:focus { outline: none }

    @include to-em(20px);
    margin: 0 auto;
    padding: 12px 0;
    width: 100%;

    @include breakpoint(tablet-vert) {
      margin: 0;
      width: auto;
      flex: 1 0 auto;
      padding: 0 10px 0 0;
    }
  }

  &__wrapper {
    &--full-width {
      width: 100%;
    }
  }

  &__example {
    padding: 15px 15px 0;
    text-align: center;

    p {
      @include to-em(12px);
      color: rgba($text, 0.7);
      margin: 0;
      font-style: italic;
    }

    @include breakpoint(tablet-vert) {
      text-align: left;
    }
  }

  &__results {
    background-color: $color-white;
    padding: 20px 18px 18px;
    display: none;

    .search-bar--results-inview & {
      display: block;
    }

    @include breakpoint(custom-tablet) {
      justify-content: space-between;
      padding: 20px 30px 30px;

      .search-bar--results-inview & {
        display: flex;
      }
    }
  }

  &__results-item {
    padding: 10px 0;
  }

  &__results-heading {
    @include font-sans(bold);
    @include to-em(18px);
    color: $color-brilliant-red;
    margin-bottom: 32px;
  }

  &__results-catalog {
    @include breakpoint(custom-tablet) {
      flex-grow: 1;
      width: 48.4%;
    }
  }

  &__results-catalog-item {
    a {
      display: flex;
      align-items: center;
    }

    img {
      height: auto;
      margin-right: 20px;
      width: 68px;
    }
  }

  &__results-materials {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid $color-border-grey;

    @include breakpoint(custom-tablet) {
      border-left: 1px solid $color-border-grey;
      border-top: 0;
      flex-grow: 1;
      margin-left: 3.2%;
      margin-top: 0;
      padding-left: 3.2%;
      padding-top: 0;
      width: 48.4%;
    }
  }

  &__close {
    background-image: url("../images/svg/close-icon.svg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 20px;
    cursor: pointer;
    display: block;
    height: 20px;
    position: absolute;
    width: 20px;
    z-index: 99;
    top: -30px;
    right: 0;

    @include breakpoint(tablet-vert) {
      right: -30px;
      top: 0;
    }
  }
}
