.products-block {
  background-color: $color-off-white;
  padding: 50px 0;

  .container {
    max-width: 1260px;
  }

  @include breakpoint(custom-tablet) {
    .container {
      align-items: flex-start;
      display: flex;
    }
  }

  &__content {
    margin-bottom: 40px;
    //position: relative;

    @include breakpoint(custom-tablet) {
      margin-bottom: 0;
      flex-grow: 1;
      max-width: (6/12 * 100%);
      order: 2;
    }

    @include breakpoint(xlarge) {
      flex: 1 0 auto;
      max-width: (7/12 * 100%);
    }


    .support-files {
      margin-top: 40px;

      .filecat {
        padding: 0 18px;
        margin-bottom: 30px;
        width: 100%;

        @include breakpoint(tablet-vert) {
          width: auto;
          flex: 1 0 50%;
        }

        @include breakpoint(xlarge) {
          width: auto;
          flex: 1 0 33.3333%;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        h4 {
          @include to-em(16px);
          color: $color-navy-blue;
          padding: 0 0 12px 0;
          border-bottom: 3px solid $color-navy-blue;
          margin-bottom: 15px;
        }

        ul {
          border-bottom: 1px solid rgba($color-navy-blue, .3);
          padding: 0 10px;

          margin: 0;

          li {
            margin: 0 0 14px;
            padding: 0 0 0 16px;

            a {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

      }
    }
  }

  &__content-grid {
    @include breakpoint(xlarge) {
      //align-items: flex-start;
      //display: flex;
    }

    &-item:not(.products-block__content-thumbnails) {
      @include breakpoint(xlarge) {
        margin-right: 70px;
      }

      @include breakpoint(xxlarge) {
        margin-right: 0;
      }

      position: relative;

      .badge-image-container {
        max-width: 120px;
        position: absolute;
        //transform: translateX(-50%);
        z-index: 1;
        top: -20px;
        left: -20px;

        @include breakpoint(tablet-vert) {
          top: 20px;
          left: -40px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &__content-thumbnails {
    margin-top: 20px;

    @include breakpoint(xlarge) {
      margin-top: 0;
      position: absolute;
      right: 40px;
      top: 180px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      display: flex;
      flex-direction: row;
      //justify-content: space-between;
      justify-content: center;

      @include breakpoint(xlarge) {
        flex-direction: column;
        margin-bottom: 20px;
      }
    }

    &-item {
      border-radius: 6px;
      cursor: pointer;
      height: 70px;
      margin: 0 5px;
      padding: 0;
      width: 70px;
      background: #fff;
      border: 1px solid $color-light-grey-4;
      position: relative;
      overflow: hidden;

      @include breakpoint(xlarge) {
        margin: 0;
      }

      a {
        display: block;
        width: 90%;
        height: 90%;
        overflow: hidden;
        margin: 5%;
        position: relative;

        &[data-open="videoModal"] {
          &:before {
            content: "";
            background-image: url("../images/svg/video-play.svg");
            background-position: center;
            background-size: contain;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            height: 20px;
            z-index: 10;
          }
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }
      }

      &:first-child {
        //margin-left: 0;
      }

      @include breakpoint(xlarge) {
        margin: 10px 0 0;

        &:first-child {
          margin-top: 0;
        }
      }

      &:before {
        content: attr(data-title);
        display: inline-block;
        background: $color-brilliant-red;
        border-radius: 3px;
        padding: 2px 8px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
        position: absolute;
        white-space: nowrap;
        color: #fff;
        font-size: 10px;
        text-transform: uppercase;
        z-index: 100;
        transition: all 200ms;
        opacity: 0;
        right: 50%;
        top: 0;
        transform: translate(50%, 0);

        @include breakpoint(xlarge) {
          right: calc(100% - 40px);
          top: 50%;
          transform: translate(0, -50%);
        }
      }

      &:hover {
        overflow: visible;
        &:before {
          top: -10px;
          opacity: 1;

          @include breakpoint(xlarge) {
            right: calc(100% - 10px);
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }
    }

    img {
      display: block;
      height: auto;
      width: 100%;
    }

    &--active {
      border: 2px solid $color-brilliant-red;
    }
  }

  &__info {
    background-color: $color-white;
    border-radius: 6px;
    padding: 20px;

    @include breakpoint(custom-tablet) {
      flex: 1 0 (5/12 * 100%);
      margin-right: (1/12 * 100%);
      max-width: 420px;
      order: 1;
      width: (5/12 * 100%);
    }

    @include breakpoint(xlarge) {
      flex: 1 0 (4/12 * 100%);
      width: (4/12 * 100%);
    }
  }

  &__warranty-ribbon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 74px;
    left: 0;
    position: absolute;
    top: 0;
    width: 75px;

    @include breakpoint(tablet-vert) {
      top: 65px;
      height: 153px;
      width: 148px;
    }

    &--three-years {
      background-image: url("../images/png/warranty-ribbon.png");
    }
  }

  &__info-btn-wrapper {
    margin-bottom: 20px;

    .btn {
      border-radius: 0;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }

    @include breakpoint(tablet-vert) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .btn {
        width: 48.4%;
        margin-top: 0;
      }
    }
  }

  &__info-heading {
    color: $color-navy-blue;
    @include to-em(25px);
  }

  &__info-nav {
    margin-top: 10px;

    ul {
      display: flex;
    }

    li {
      @include to-em(12px);
      margin-left: 1em;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      @include font-sans(medium);
      color: $color-navy-blue;

      &::before {
        content: "\025B8";
        margin-right: 5px;
      }
    }
  }

  &__info-content {
    margin-top: 20px;

    p {
      @include to-em(13px);
      line-height: 1.7;
    }
  }

  &__info-content-block {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    .u-styled-list, .wysiwyg ol, .wysiwyg ul {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    // T
    &.dd-override {
      table {
        margin-top: 0;
        margin-bottom: 15px;
      }
      td {
        font-size: .7em;
        padding: 5px;
      }
      th {
        padding-bottom: 0.625rem;
      }  
      
    }
  }

  &__info-content-image {
    position: relative;

    img {
      display: block;
      height: auto;
      width: 100%;

      &.media--image {
        width: 100%;

        @include breakpoint(tablet-vert) {
          width: calc(100% + 60px);
          max-width: none;
          margin: 0 -30px;
        }
      }
    }
  }

  &__info-content-heading {
    @include to-em(18px);
    line-height: 140%;
    font-weight: 400;
    margin-bottom: 12px;
    color: $color-brilliant-red;
  }

  &__info-content-btn {
    margin-top: 20px;
  }

  &__info-content-scta {
    margin: 15px 0;
    display: inline-block;
    font-size: .8em;
    //text-transform: uppercase;
    text-align: left;
    transition: all 200ms;
    color: $color-navy-blue;

    &:before {
      content: "\025B8";
      margin-right: 5px;
    }

    &:hover {
      color: lighten($color-navy-blue, 10%);
    }
  }

  &__info-content-form {
    .global-form__col {
      margin-top: 10px;
    }

    .custom-select {
      padding: 12px 20px;
    }
  }

  &__info-content-data {
    @include breakpoint(tablet-vert) {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .u-styled-list {
      li {
        @include to-em(14px);
        margin-top: 12px;
      }
    }
  }

  &__info-content-data-item {
    margin-top: 24px;

    @include breakpoint(tablet-vert) {
      flex-grow: 1;
      margin-left: 3.2%;
      width: 48.4%;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__info-content-data-item-heading {
    @include to-em(20px);
    @include font-sans(bold);
    color: $color-navy-blue;
    border-bottom: 3px solid $color-navy-blue;
    padding-bottom: 12px;
  }

  &__col-input {
    &--power {
      @include breakpoint(tablet-vert) {
        width: 36%;
        flex-grow: 1;
        margin-right: 20px;
      }
    }

    &--lock {
      @include breakpoint(tablet-vert) {
        flex-grow: 2;
        width: 60%;
      }
    }
  }
}

.reveal.product-reveal {

  @include breakpoint(tablet-vert) {
    width: 65%;
  }

  overflow: visible;
  border-radius: 6px;

  .close-button {
    position: absolute;
    right: -45px;
    color: #fff;
    font-weight: 800;
    top: -8px;
  }

  .video-wrapper {

    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    //iframe {
    //  width: 100%;
    //  height: 100%;
    //}
  }
}

