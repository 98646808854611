.product-category {
  background-color: $color-off-white;
  padding: 40px 0 0;

  &__grid {

  }

  &__grid-item {
    margin-top: 20px;
  }

  // Each block
  &__grid-category {
    background-color: $color-white;
    border: 3px solid $color-white;
    border-radius: 6px;
    margin-top: 20px;
    flex-grow: 1;

    &:first-child {
      margin-top: 0;
    }
  }

  &__grid-item-image {

    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px 3px 0 0;
    overflow: hidden;

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  &__grid-content {
    padding: 30px;

    p {
      @include to-em(13px);
    }
  }

  &__grid-heading {
    @include to-em(25px);
    color: $color-navy-blue;

    a {
      color: $color-navy-blue;
      transition: all 400ms;

      &:hover {
        color: $color-brilliant-red;
      }
    }
  }

  &__grid-nav {

    margin-left: -2px;
    margin-right: -2px;


    > div {
      padding: 2px;
    }

    a {
      border: 1px solid $color-border-grey;
      color: $text;
      display: block;
      padding: 10px 0;
      text-align: center;
      transition: ease all 0.3s;

      span {
        @include to-em(14px, 18px);
        color: $color-border-grey;
      }

      &:hover {
        color: $color-white;
        background-color: $color-navy-blue;
        border-color: $color-navy-blue;
      }
    }

  }

  &__accessory-grid {
    @include to-em(16px);
    @include font-sans(bold);
    text-align: center;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 25px;
  }

  &__accessory-grid-item {
    color: $color-navy-blue;
    padding: 5px;

    &:hover {
      img {
        //transform: scale(1.01);
        //box-shadow: 0 5px 25px 0 rgba(0,0,0,.2);
        opacity: .9;
      }
    }

    a {
      background-color: $color-off-white;
      display: block;
      border-radius: 6px;
      overflow: hidden;
      //transition: all 400ms;

      span {
        &.title {
          padding: 10px;
          display: block;
          font-size: .9em;
          font-weight: 500;
          color: $color-navy-blue;
        }
      }
    }

    figure {
      border-radius: 6px 6px 0 0;
      background: $medium-gray;
    }

    img {
      display: block;
      height: auto;
      width: 100%;
      border-radius: 6px 6px 0 0;
      transition: all 400ms;
    }
  }

  .top-block-wrapper {//this is overriding the default grid behaviour
    display: block;
    columns: 400px 2;

    .top-block {
      width: 100%!important;
      break-inside: avoid;
      page-break-inside: avoid;
      display: block;
      background: #fff;
      margin-bottom: 20px;
      padding: 3px;
      border-radius: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
