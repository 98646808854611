#configure-container {
    //z-index: 100; // Full screen will overlap the top nav, as desired.
    // Other option is to position the top border of the full screen below the nav.

    .configure-container-wrap.full-screen-on .configure-product-display {
        top: 65px;

        @include breakpoint(phone-small) {
            top: 65px;
        }
        @include breakpoint(phone-medium) {
            top: 75px;
        }
        @include breakpoint(phone-horiz-tiny) {
            top: 75px;
        }
        @include breakpoint(medium) {
            top: 75px;
        }
        @include breakpoint(tablet-vert) {
            top: 75px;
        }
        @include breakpoint(custom-tablet) {
            top: 75px;
        }
        @include breakpoint(large) {
            top: 100px;
        }
    }
}