.company-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 45px;
  width: 200px;

  @include breakpoint(phone-medium) {
    width: 270px;
    height: 60px;
  }
}




.masthead {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .btn {
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 20px;
  }

  .masthead__sub-heading {
    @include to-em(16px);
    letter-spacing: 0.02em;

    & + .btn {
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }

    @include breakpoint(tablet-vert) {
      @include to-em(17.5px);
      & + .btn {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }

  // Sizing similar to the home page (but without static asset declarations)
  &--full, &--home {
    height: 75vh;
    padding-bottom: 0;

    @include breakpoint(tablet-vert) {
      height: 85vh;
      min-height: 650px;
      padding-bottom: 120px;
    }

    .masthead__sub-heading {
      & + .btn {
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
      }

      @include breakpoint(tablet-vert) {
        & + .btn {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }

    & + * {

      background-color: $color-white;
      margin: 0 auto;
      max-width: 1220px;
      width: 90%;
      z-index: 99;
      position: relative;

      &:after {
        content: "";
        background: #fff;
        display: block;
        position: absolute;
        width: 100vw;
        transform: translateX(-50%);
        left: 50%;
        top: 0;
        bottom: 0;
        z-index: -1;
      }

      @include breakpoint(tablet-vert) {
        border-radius: 8px 8px 0 0;
        margin-top: -200px!important;
        padding: 40px!important;
        position: relative;
        z-index: 1;

        & > .container {
          width: 100%;
        }

        &:after {
          top: 200px;
        }

      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%;
      z-index: 1;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
      background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      opacity: .3;
    }

    section {
      position: relative;
      z-index: 2;
    }

    header {
      position: relative;
      z-index: 3;
    }

  }

  // Sizing similar to the About page (without static asset declarations)
  &--large, &--about {
    height: 55vh;
    padding-bottom: 0;

    .masthead__header {
      z-index: 99;
    }

    @include breakpoint(tablet-vert) {
      min-height: 500px;
      padding-bottom: 120px;
    }

    .masthead__sub-heading {
      & + .btn {
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
      }

      @include breakpoint(tablet-vert) {
        & + .btn {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }

    & + * {

      background-color: $color-white;
      margin: 0 auto;
      max-width: 1220px;
      width: 90%;
      z-index: 99;
      position: relative;

      &:after {
        content: "";
        background: #fff;
        display: block;
        position: absolute;
        width: 100vw;
        transform: translateX(-50%);
        left: 50%;
        top: 0;
        bottom: 0;
        z-index: -1;
      }

      @include breakpoint(tablet-vert) {
        border-radius: 8px 8px 0 0;
        margin-top: -200px!important;
        padding: 40px!important;
        position: relative;
        z-index: 1;

        & > .container {
          width: 100%;
        }

        &:after {
          top: 200px;
        }

      }

    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%;
      z-index: 1;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
      background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      opacity: .3;
    }

    header, section {
      position: relative;
      z-index: 2;
    }

  }

  // Just enough to allow for rounded-corner open-text
  &--simple, &--support, &--blog {
    padding: 0;

    .masthead__header {
      background: rgba($white, .9);
      z-index: 99;

      /*.company-logo {
        background-image: url("../images/svg/hope-logo-color.svg");
        background-size: contain;
      }*/
    }

    .masthead__content-wrapper {
      @include breakpoint(tablet-vert) {
        padding: 80px 0 20px;
      }
    }
  }

  .masthead__content {
    padding-top: 0px;
  }

  &--singlepost {
    background-image: url("../images/jpg/about-masthead-bg.jpg");
    padding-bottom: 0;

    .masthead__content-wrapper { @include breakpoint(tablet-vert) { padding: 50px 0 20px; } }
    .masthead__content { @include breakpoint(tablet-vert) {max-width: 55% } }
  }


  // Static assets for static versions of pages.
  &--home {
    background-image: url("../images/jpg/home-masthead.jpg");
  }

  &--about {
    background-image: url("../images/jpg/about-masthead-bg.jpg");
  }


  &--customers {
    background-image: url("../images/jpg/customers-masthead-bg.jpg");

    @include breakpoint(tablet-vert) {
      height: 545px;
    }
  }

  &--contact {
    background-image: url("../images/jpg/contact-masthead-bg.jpg");

    @include breakpoint(tablet-vert) {
      height: 532px;

      .masthead__content-wrapper--padded {
        align-items: flex-end;
      }
    }
  }

  &--seo {
    background-image: url("../images/jpg/seo-landing-bg.jpg");
    padding-bottom: 60px;

    .masthead__content-wrapper {
      padding-top: 60px;
    }

    @include breakpoint(tablet-vert) {
      height: 814px;

      .masthead__content-wrapper {
        padding-top: 0;
      }

      .masthead__sub-heading {
        @include to-em(16px);
      }

      .masthead__content-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &--product-support {
    background-image: url("../images/png/product-support-bg.png");

    @include breakpoint(tablet-vert) {
      //height: 350px;
    }

    @include breakpoint(xlarge) {
      //height: 280px;

      .masthead__content-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &--flexed {
    @include breakpoint(tablet-vert) {
      .masthead__content-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &__heading {
    @include to-em(28px);
    font-weight: 800;
    line-height: 1.2;
    letter-spacing: 0.02em;

    span {
      @include font-sans(book);
      @include to-em(28px, 50px);
      display: block;
      margin-bottom: 10px;
    }

    @include breakpoint(tablet-vert) {
      @include to-em(40px);

      span {
        margin-bottom: 20px;
      }
    }
  }

  &__sub-heading {
    @include to-em(15px);
    letter-spacing: 0.02em;
    margin-top: 30px;

    & + .btn {
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }

    @include breakpoint(tablet-vert) {
      @include to-em(17px);
      & + .btn {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }

  &__text {
    @include to-em(18px);
    font-weight: bold;
    line-height: 1.555555556;
  }

  &__header {
    padding-top: 15px;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @include breakpoint(large) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &__content-wrapper {

    .grid-x {
      width: 100%;
    }

    &--padded {
      padding-top: 60px;

      display: flex;
      align-items: flex-end;

      .masthead--full & {
        align-items: center;
      }

      @include breakpoint(tablet-vert) {
        padding-top: 0;
        height: calc(100% - 220px);
      }
    }

    &--padded-100 {
      //padding-top: 120px;
      //height: calc(100% - 165px);
      padding: 30px 0 20px;

      @include breakpoint(tablet-vert) {

        //padding-top: 100px;
        display: flex;
        align-items: center;
        padding: 0;
      }
    }
  }

  &__content {
    color: #fff;
    text-align: center;

    @include breakpoint(tablet-vert) {
      text-align: left;
    }

    &--full-width {
      @include breakpoint(tablet-vert) {
        max-width: none;
      }
    }
  }

  &__image {
    transform: translateX(40px);

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  &__info {
    @include to-em(14px);
    text-align: center;
    color: $color-white;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin: 0;
      padding: 0;

      img {
        width: 30px;
        height: 30px;
      }
    }

    a {
      color: $color-white;
      text-decoration: underline;
    }

    flex: 1 0 auto;

    p {
      margin: 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &.icon-feeds {
        li {
          margin-left: 34px;

          &:first-child {
            margin-left: 0;

            &::before {
              display: none;
            }
          }

          &::before {
            content: "";
            position: absolute;
            background-color: $color-white;
            width: 1px;
            height: 14px;
            left: -17px;
            top: 50%;
            display: block;
            margin-top: -7px;
          }
        }
      }
    }

    li {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        background-color: $color-white;
        width: 1px;
        height: 14px;
        left: -12px;
        top: 50%;
        display: block;
        margin-top: -7px;
      }

      margin-left: 24px;

      &:first-child {
        margin-left: 0;

        &::before {
          display: none;
        }
      }
    }

    @include breakpoint(tablet-vert) {
      text-align: right;

      ul {
        justify-content: flex-end;
      }
    }

  }

  body.activeNav & {
    z-index: 999;
  }

}

// .page-template-configurator-frame
.page-template-configurator-frame {
  .masthead__content-wrapper {
    display: none;
  }
}

.img-bg-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}

.masthead-full-filler {
  height: 75vh;
  padding-bottom: 0;
  background: $color-navy-blue;
  position: relative;
  z-index: -1;

  @include breakpoint(tablet-vert) {
    height: 85vh;
    min-height: 650px;
    padding-bottom: 120px;
  }
}

.masthead-large-filler {
  height: 55vh;
  padding-bottom: 0;
  background: $color-navy-blue;
  position: relative;
  z-index: -1;

  @include breakpoint(tablet-vert) {
    min-height: 500px;
    padding-bottom: 120px;
  }
}
