.pivot-container {
	margin-left: -5px;
	margin-right: -5px;

	div.cell {
		margin-left: 0;
		margin-right: 0;

		padding: 0 5px;
		margin-bottom: 10px;

		width: 50%;

		@include breakpoint(tablet-vert) {
			width: 33.3333%;
		}
	}
}

.pivot {
	display: block;
	text-align: center;
	border: 1px $color-light-grey-4 solid;
	font-size: rem-calc(11px);
	color: $dark-gray;
	transition: all 100ms;

	&:hover {
		color: $color-white;
		background-color: $dark-gray;


		.pivot__value {
			background-color: $very-dark-gray;
		}
	}

	&.active {
		font-weight: bold;
		background-color: $color-navy-blue;
		color: $white;

		&:hover {
			background-color: $color-navy-blue;
			cursor: default;

			.pivot__value {
				background: $color-dark-navy-blue;
			}
		}

		.pivot__value {
			background: $color-dark-navy-blue;
		}
	}

	&__key {
		font-size: rem-calc(15px);
		transition: all 100ms;
		padding: 6px 2px;
	}

	&__value {
		background: $light-gray;
		transition: all 100ms;
		padding: 4px 2px;
		width: 100%;
	}
}

.configurator-text {
	display: none;
	padding: 0 20px 10px;

	&.is-active {
		display: block;
	}

	ul {
		li {
			@include to-em(12px);
			margin: 0 0 12px;
			padding: 0 0 0 20px;
		}
	}
}

.demo-image, .media-entry img, .media-entry iframe, .media-link img {
	width: 90%;
	height: auto;
	margin-left: auto;
	margin-right: auto;

	&.hidden {
		display: none;
	}
}

.image-nav a {
	display: block;
	margin-bottom: 5px;
}

.image-container {
	text-align: center;	
}

#specs-panel {

	ul, ol {
		//column-count: 2;
		//moving to a global class
	}
}

.products-block {
	.dropdowns-container {
		display: flex;
		flex-wrap: wrap;
		margin-left: -5px;
		margin-right: -5px;
		margin-bottom: 15px;

		.ddl-wrapper {
			flex: 1 0 auto;
			padding: 5px;
			max-width: 100%;

			&[data-att = 'Power'] {
				flex: 1 0 100%;

				@include breakpoint(tablet-vert){
					flex: 1 0 auto;
				}
			}

			&[data-att = 'Window'] {
				flex: 2 0 auto;
			}

			&[data-att = 'Bezel Material'] {
				flex: 1 0 100%;
			}

			select {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;

				background: #fff;
				border: 1px solid $color-light-grey-4;
				color: $dark-gray;
				@include to-em(14px);
				padding: 15px 40px 15px 10px;
				background-image: url("../images/svg/ddl-accent.svg");
				background-position: calc(100% - 15px) center;
				background-size: 15px;
				background-repeat: no-repeat;
			}
		}
	}

	h5.miniconfig-labels {
		@include to-em(11px);
		margin-bottom: 5px;
	}
	
	h6.media--caption {
		font-weight: 300;
		font-size: .7em;
		margin: 15px 0;
		text-align: left;
	}
}

.zoomContainer {
	z-index: 99; // Puts it in front of everything on the Product page.
}

.zoomWindow {
	box-shadow: 0 0 50px rgba(0,0,0,.2);
}

.zoomWindowContainer, .zoomContainer {
	//not sure why this is needed, but it's causing some strange stuff without it.
	width: 0!important;
	height: 0!important;
}