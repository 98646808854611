.u-center-border {
  display: block;
  letter-spacing: 0.05em;
  margin-bottom: 35px;
  position: relative;
  text-align: center;
  z-index: 2;
  @include to-em(12px);

  ul {
    background-color: $color-white;
    list-style: none;
    margin: 0 auto;
    max-width: 290px;
    padding: 0;
    position: relative;
    width: 90%;
    z-index: 3;

    @include breakpoint(large) {
      display: flex;
      max-width: 810px;
      justify-content: center;
    }
  }

  li {
    @include to-em(13px);
    display: block;
    // width: 280px;
    // padding: 0 20px;
    margin: 0 auto;

    @include breakpoint(large) {
      background-color: $color-white;
      border-left: 1px solid $color-border-grey;
      padding: 0 20px;

      &:first-child {
        border-left: 0;
      }
    }
  }

  span {
    @include font-sans(bold);
    //@include to-em(12px);
    background-color: #fff;
    color: $color-navy-blue;
    padding: 0 16px;
    position: relative;
    text-transform: uppercase;
    z-index: 2;

    @include breakpoint(phone-small down) {
      display: block;
      margin: 0 auto;
      padding: 0;
      width: 85%;
    }
  }

  &::after {
    background-color: $color-border-grey;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    margin-top: -0.5px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
    z-index: 1;
  }
}

.u-round-section {
  background-color: $color-white;
  margin: 0 auto;
  max-width: 1220px;
  width: 90%;

  /*@include breakpoint(tablet-vert) {
    border-radius: 8px;
    margin-top: -120px;
  }*/
}

// This needs to be the list style for all UL's because we'll not be able to add classes to wysiwyg UL's. And add an OL style to match.
// Zach: Here's a list of every wrapper where this UL class (u-styled-list) is used, so we can keep them (and add on) when we also add this style to wysiwygs.
/*
  .product-support-content__aside-nav ul
  .support-content__item ul,
  .products-block__info-content-block ul
  .products-block-details__accordion-content ul
  .solutions-gallery__grid-build ul
*/




.u-styled-list, .wysiwyg ul, .wysiwyg ol {
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
  font-size: inherit;

  li {
    //@include to-em(13px);
    font-size: 0.8125rem;
    margin: 0 0 24px;
    //margin: 24px 0 0; // Top margins cause weirdness when we do column-count: 2
    padding: 0 0 0 15px;
    position: relative;

    & > ul, & > ol {
      margin-top: 24px;

      li {
        font-size: inherit;

        a {
          color: $color-navy-blue;
        }
      }
    }

    &.active {
      a {
        color: $color-brilliant-red;
      }
    }

  }

  a {
    color: $color-navy-blue;
  }

}

ul.u-styled-list, .wysiwyg ul {

  li::before {
      background-image: url("../images/png/list-item-arrow.png");
      background-position: top center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: block;
      height: 10px;
      left: 0;
      position: absolute;
      top: 4px;
      width: 6px;
  }  
}

ol.u-styled-list, .wysiwyg ol {
  counter-reset: li;

  li {
    padding: 0 0 0 35px;
  }

  li::before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-color: $dark-pink;
    border-radius: 10px;
    text-align: center;
    color: $color-white;
  }
}

ul, ol {
  &.three-column {
    columns: 300px 3;
    column-gap: 40px;

    li {
      page-break-inside: avoid;
      display: block;
      break-inside: avoid;
    }
  }

  &.two-column {
    columns: 300px 2;
    column-gap: 40px;

    li {
      page-break-inside: avoid;
      display: block;
      break-inside: avoid;
    }
  }
}

/*
//BACKUP:
.u-styled-list {
  list-style: none;
  margin: 0;
  padding: 0;

  &--arrow {
    li {
      position: relative;

      &::before {
        background-image: url("../images/png/list-item-arrow.png");
        background-position: top center;
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        display: block;
        height: 10px;
        left: 0;
        position: absolute;
        top: 4px;
        width: 6px;
      }
    }
  }

  li {
    @include to-em(13px);
    margin: 24px 0 0;
    padding: 0 0 0 20px;
    position: relative;
  }

  a {
    color: $color-navy-blue;
  }
}
*/


.u-styled-table {
  thead {
    td,
    th {
      @include font-sans(bold);
      color: $color-navy-blue;
    }
  }
}


.imageModal {

}

section.enlarge-logo {
  .company-logo {
    width: 320px;
    height: 70px;
  }
}

.olark-unavailable {
  display: none;
}

.cable-column {
  padding-top: 0!important;
  &:first-of-type {
    margin-top: 40px;
  }

  h2 {
    border-bottom: 1px solid $color-border-grey;
    padding-bottom: 20px;
  }

  @include breakpoint(tablet-vert) {
    .open-text__copy {
      display: flex;

      > * {
        flex: 1 0 50%;
        width: 50%;
      }
    }
  }
}