// Color names courtesy of http://www.colorhexa.com

$black: #000000;
$white: #ffffff;
$mostly-white: #f6f6f6;
$very-light-gray: #f5f5f5;
$light-gray: #f0f0f0;
$dark-gray: #787878;
$very-dark-gray: #6d6d6d;

$dark-pink: #B20838;
$very-dark-blue: #234A71;
$slightly-desaturated-blue: #6B96BE;
$dark-nav-color: #2C3840;

$sixty-white: rgba($white,0.6);
$ninetyfive-white: rgba($white,0.95);


//$light-gray: #f5f5f5;
$medium-gray: #787878;
//$dark-gray: #6d6d6d;
$black: #0a0a0a;
$white: #fefefe;

// Imports from Doug
$color-black: $black; // equivalent
$color-white: $white; // equivalent
$color-off-white: $light-gray; // equivalent
$color-border-grey: #aeaeae;
$color-navy-blue: #234a71;
$color-dark-navy-blue: #204468;
$color-nice-blue: #6b96be;
$color-dark-blue: #0c1824;
$color-light-grey-1: #f4f4f4;
$color-light-grey-2: #e8e8e8;
$color-light-grey-3: #c7c3c1;
$color-light-grey-4: #b3b3b3;
$color-dark-grey-1: $dark-gray; // equivalent
$color-brilliant-red: #b20939;



// Structural Color definitions
$bodyBackgroundColor: $white;
$bodyTextColor: $very-dark-blue;

$sectionBackgroundColor: $very-light-gray;
$sectionTextColor: $dark-gray;

$lightOnDarkTextColor: $white;
$darkOnLightTextColor: $very-dark-blue;

$captionTextColor: $very-dark-gray;

$button1TextColor: $white;
$button1BackgroundColor: $dark-pink;
$button1BorderColor: $dark-pink;

$button2TextColor: $white;
$button2BackgroundColor: transparent;
$button2BorderColor: $white;

$button3TextColor: $very-dark-blue;
$button3BackgroundColor: $white;
$button3BorderColor: $very-dark-blue;

$button4TextColor: $white;
$button4BackgroundColor: $very-dark-blue;
$button4BorderColor: $very-dark-blue;

$headingColor1: $very-dark-blue;
$headingColor2: $dark-pink;

$headerMenuSection: $white;
$headerMenuItem: $very-light-gray;

$footerMenuSection: $slightly-desaturated-blue;
$footerMenuItem: $very-light-gray;