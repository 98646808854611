.c-sitemap {
  padding: 105px 0;

  .container {
    max-width: 980px;
  }

  &__heading {
    @include to-em(20px);
    color: $color-navy-blue;
    border-bottom: 1px solid $color-border-grey;
    padding: 0 26px 20px;
  }

  &__item {
    margin-top: 50px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__item-content {
    @include breakpoint(tablet-vert) {
      display: flex;
    }
  }

  &__item-col {
    @include breakpoint(tablet-vert) {
      width: 50%;
      flex-grow: 1;
    }

    & > ul {
      padding-left: 26px;
    }

    a {
      color: $color-navy-blue;
    }

    li {
      @include font-sans(bold);
      @include to-em(12px);
      margin-top: 30px;
      text-transform: uppercase;

      ul {
        margin-left: 20px;

        @include breakpoint(tablet-vert) {
          margin-left: 35px;
        }

        li {
          @include to-em(14px);
          @include font-sans(book);
        }

        a {
          color: $text;
        }
      }
    }
  }
}
