body.page-template-page-basic-content .basic-content-wrapper {
  @include breakpoint(custom-tablet){
    display: flex;
    margin: 0 auto;
    max-width: 1220px;
    width: 90%;
    align-items: flex-start;

    .content-main {
      max-width: 920px;
      padding-right: 100px;
      flex: 0 1 auto;

      .container {
        width: 100%;
      }
    }
  }
}

.product-support-content {
  &__wrapper {
    padding-bottom: 40px;
    .container {
      @include breakpoint(tablet-vert) {
        //display: flex;
        //align-items: flex-start;
        //justify-content: space-between;
      }
    }
  }

  &__content, &__wrapper .flexible-content-wrapper {
    //@include breakpoint(tablet-vert) {
    //  max-width: 880px;
    //  padding-right: 100px;
    //  flex: 1 0 auto;
    //}

    &.no-sidebar {
      max-width: none;
      padding-right: 0;
    }

    p {
      @include to-em(15px);
      line-height: 190%;
      margin-bottom: 20px;
    }

    h2 {
      font-weight: 700;
      color: $color-brilliant-red;
      margin: .85em 0;
      @include to-em(25px);
    }

    h3 {
      @include font-sans(bold);
      color: $color-brilliant-red;
      margin: .85em 0;
      @include to-em(20px);
    }

    h4 {
      font-weight: bold;
      color: $dark-gray;
      margin: .5em 0;
      @include to-em(16px);
    }

    h5 {
      font-weight: bold;
      color: $dark-gray;
      margin: .5em 0;
      @include to-em(15px);
    }

    a {
      color: $color-brilliant-red;
    }

    ul:not(.u-styled-list), ol:not(.u-styled-list) {
      margin: 0;
      padding: 0 0 25px 40px;
      @include to-em(15px);
      line-height: 190%;

      li {
        margin: 0 0 8px;
        padding: 0;

        &:last-of-type {
          margin: 0;
        }
      }
    }

    .u-styled-list {
      a {
        color: $color-navy-blue;
      }
    }
  }

  &__aside {
    background-color: $color-off-white;
    padding: 30px;

    @include breakpoint(custom-tablet) {
      max-width: 300px;
      flex: 1 0 auto;
      margin-top: 80px;
    }

    p {
      @include to-em(14px);
      line-height: 150%;
      margin-bottom: 0;
      margin-top: 0;

      & + p {
        margin-top: 15px;
      }
    }

    .u-styled-list {
      margin: 0;

      li {
        @include to-em(15px);

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        &:hover,
        &.active {
          color: $color-brilliant-red;
        }
      }

      & + .sidebar-open-content p {
        margin-top: 15px;
      }

    }
  }

  //for the flex content stuff
  &__wrapper {
    .flexible-content {

      &:first-of-type {
        padding-top: 0!important;
      }

      .container {
        @include breakpoint(tablet-vert) {
          display: block;
          margin: 0 auto;
          max-width: none;
          width: 100%;
        }
      }

      //now the overrides
      &.accordions {
        padding: 30px 0;

        h2 {
          text-align: left;
          @include to-em(20px);
          color: $color-brilliant-red;
        }

        .c-accordion {

          &__items {

          }

          &__item {
            padding: 25px 0 5px;

            &-heading {
              font-weight: 700;
              @include to-em(16px);
              color: $color-navy-blue;
              margin: 0;
            }
          }
        }
      }

      &.block-carousel {
        padding: 30px 0;

        h2 {
          text-align: left;
          @include to-em(20px);
          color: $color-brilliant-red;
        }

        .block-carousel__carousel {
          margin-top: 0;
        }
      }

      &.bulletin-points {
        border-radius: 8px;
        padding: 60px 30px;

        h2 {
          @include to-em(35px);
        }

        a.btn {
          color: $color-white;
          text-decoration: none;

          &:hover {
            color: $color-navy-blue;
          }
        }
      }

      &.client-carousel {

      }

      &.company-grid {
        padding: 30px 0;

        a {
          color: $color-navy-blue;

          &:hover {
            color: $color-white;
          }
        }
      }

      &.open-text {
        padding: 30px 0;

        h2 {
          text-align: left;
          @include to-em(20px);
          color: $color-brilliant-red;
          margin-bottom: 20px;
        }

        .main-container {
          padding: 0;
          max-width: none;
        }

        p:last-of-type {
          margin-bottom: 0;
        }
      }

      &.product-compare {
        padding: 30px 0;

        .product-compare__header {
          text-align: left;
        }

        h2 {
          text-align: left;
          @include to-em(20px);
          color: $color-brilliant-red;
        }

        .monitor-table {
          padding: 0;
        }
      }

      &.product-groups {
        h2 {
          text-align: left;
          @include to-em(20px);
          color: $color-brilliant-red;
          margin-bottom: 20px;
        }

        .container {
          padding: 30px 0;

          .product-groups__grid {
            margin: 0 -7px;
          }

          .product-groups__grid-item {
            padding: 7px;
            text-align: left;
            display: flex;
            flex-direction: column;

            &-heading {
              background: $light-gray;
              margin: 0;
              padding: 15px 20px;
              @include to-em(16px);
              color: $color-navy-blue;
            }

            &-caption {
              background: $light-gray;
              margin: 0;
              padding: 0 20px 15px;
              flex: 1 0 auto;
              @include to-em(13px);
              border: 3px solid $light-gray;
              border-radius: 0 0 5px 5px;

              .u-styled-list li, .wysiwyg ul li, .wysiwyg ol li {
                margin: 0 0 15px;
              }
            }

            &-image {
              background: $light-gray;
              border: 3px solid $light-gray;
              border-radius: 5px 5px 0 0;
              margin: 0;
              overflow: hidden;
            }

            .product-groups__btn {
              background: $color-navy-blue;
              border: 3px solid $light-gray;
              border-radius: 0 0 5px 5px;
              color: #fff;
              overflow: hidden;
              margin: 0;

              display: none;
            }
          }
        }
      }

      &.related-products {
        padding: 30px 0;

        h2 {
          text-align: left;
          @include to-em(20px);
          color: $color-brilliant-red;
        }

        .related-products__item-grid {
          margin-left: -7px;
          margin-right: -7px;

          .related-products__item {
            margin-left: 7px;
            margin-right: 7px;

            width: calc(25% - 14px);

            h4 {
              font-size: .8em;
            }
          }
        }
      }

      &.text-tiles {
        padding: 30px 0;

        h2 {
          text-align: left;
          @include to-em(20px);
          color: $color-brilliant-red;
        }

        .text-tile__link {
          color: $color-navy-blue;

          &:hover {
            color: $color-white;
          }
        }
      }

      &.timeline {
        border-radius: 8px;
        padding: 60px 30px;

        h2 {
          @include to-em(35px);
        }

        h3 {
          color: $color-white;
          margin-bottom: 10px;
        }

        p {
          line-height: 150%;
        }
      }

    }
  }
}

.support-downloads__item > .c-accordion__content--table {
  margin-left: 20px;
  margin-right: 20px;
}
