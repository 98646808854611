.company-grid {
  padding: 20px 0;
  background: #fff;

  @include breakpoint(tablet-vert){
    padding: 60px;
  }

  .grid-x {
    align-items: flex-start;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex !important; // overrides slick
    flex-wrap: wrap;
    width: 100%;

    overflow: hidden;
  }

  &__list-item {
    //display: none;
    margin: 0;
    padding: 0;
    width: 50%;
    flex: 0 1 ((3/6) * 100%);
    position: relative;
    text-align: center;
    top: 1px;
    left: 1px;

    .logo-container {
      border-right: 1px solid rgba($color-border-grey, .4);
      border-bottom: 1px solid rgba($color-border-grey, .4);
    }

    img {
      display: block;
    }

    @include breakpoint(phone-horiz-tiny) {
      width: ((1/3) * 100%);
      flex: 0 1 ((1/3) * 100%);
    }

    @include breakpoint(medium) {
      width: ((1/4) * 100%);
      flex: 0 1 ((1/4) * 100%);
    }

    @include breakpoint(tablet-vert) {
      width: ((1/6) * 100%);
      flex: 0 1 ((1/6) * 100%);
    }

    @include breakpoint(custom-tablet) {
      width: ((.75/6) * 100%);
      flex: 0 1 ((.75/6) * 100%);
    }

  }

  &__list-item.active {
    //display: block;
    position: relative;
    top: 1px;
    left: 1px;

    @include ieOnly {
      left: 2px;
    }
  }

  &__list-item.inactive {
    display: none!important;
  }


  &__case-study {
    @include to-em(10px);
    @include font-sans(medium);
    color: $color-navy-blue;
    text-transform: uppercase;
    text-align: center;
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 15px;
    z-index: 2;
  }

  .btn {
    width: 162px;
    margin: 65px auto 0;
    display: block;
  }

  .sidebar-industries {
    display: none;
    
    @include breakpoint(large){
      display: block;
    }
    
    padding-right: 20px;
    h4 {
      @include to-em(12px);
      text-transform: uppercase;
      margin: 0 0 30px 0;
      padding: 0 0 10px 0;
      border-bottom: 1px solid $color-border-grey;
    }
  }

  .company-industry {
    &__list {
      margin: 0;
      padding: 0;
    }

    &__list-item.is-active {
      font-weight: bold;

      a {
        //&:after {
        //  content: "";
        //  display: inline-block;
        //  width: 10px;
        //  height: 10px;
        //  background: url(../images/svg/blue-close-icon.svg) center center no-repeat;
        //  background-size: cover;
        //  margin-left: 20px;
        //}

        &.all-customers:after {
            display: none;
        }
      }
    }
  }
}

