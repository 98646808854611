.c-search-results {
  padding: 50px 0;

  .container {
    max-width: 980px;
  }

  @include breakpoint(tablet-vert) {
    padding: 50px 0 120px;
  }

  &__heading {
    @include to-em(25px);
    color: $color-navy-blue;
    margin-bottom: 26px;
    text-align: center;
  }




  &__nav {

    border: 0;
    background: transparent;
    padding: 20px 0 60px;
    width: 85%;
    margin: 0 auto;

    //margin-bottom: 70px;

    ul {
      margin-right: -10px;
      margin-left: -10px;

      @include breakpoint(tablet-vert) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }

      @include breakpoint(large) {
        flex-wrap: nowrap;
      }
    }

    li {
      //margin-top: 10px;

      @include breakpoint(tablet-vert) {
        margin: 0 10px;
        //min-width: 180px;
        //width: calc(50% - 10px);
        //flex-grow: 1;
        flex: 1 0 50%;
      }

      //@include breakpoint(large) {
      //  width: auto;
      //  margin-left: 20px;
      //
      //  &:nth-of-type(2n-1) {
      //    margin-left: 20px;
      //  }
      //}

      a {
        transition: ease all 0.3s;
        //margin-right: 10px;
        //margin-left: 0;


        font-size: rem-calc(14px);
        padding: 15px 25px;
        color: $color-navy-blue;
        font-weight: 400;

        &:hover {
          background-color: rgba($color-navy-blue, .1);
          color: $color-navy-blue;
        }

        &.is-active {
          background-color: $color-navy-blue;
          color: $color-white;
        }
      }
    }

    .btn {
      border-radius: 0;
      padding: 12px 50px;

      &.active {
        background-color: $color-navy-blue;
        color: $color-white;
      }
    }
  }


  &__item {
    border: 3px solid #F0F0F0;
    background: #f0f0f0;
    margin-top: 16px;
    border-radius: 6px;

    @include breakpoint(tablet-vert) {
      display: flex;
      align-items: stretch;
    }
  }

  &__item-image {
    background-color: #f6f6f6;
    background-origin: content-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    min-height: 100px;
    min-width: 200px;
    padding: 26px;

    @include breakpoint(tablet-vert) {
      padding: 10px;
      width: 20%;
    }
  }

  &__item-content {
    padding: 20px 20px;

    @include breakpoint(tablet-vert) {
      padding: 20px 30px;
    }

    p {
      margin-bottom: 0;
      font-size: .8em;
    }
  }

  &__item-heading {
    @include to-em(18px);
    color: $color-navy-blue;
    margin-bottom: 0;

    a {
      color: $color-navy-blue;
    }

    & + p {
      margin-top: 5px;
    }
  }

  &__pagination {
    // border was here
    margin: 40px auto 0;
    max-width: 244px;
    padding: 0;
    position: relative;
    text-align: center;

    display: none;

    ul {
      border: 2px solid $color-navy-blue; // border moved here
      display: inline-block; // was flex
      //justify-content: center;
      padding: 10px;

      li {
        display: inline-block;

        &.active {
          background: rgba($color-navy-blue, .1);
        }
      }
    }

    a {
      @include font-sans(bold);
      color: $color-navy-blue;
      padding: 8px 10px;
      display: block;
    }
  }

  &__pagination-btn {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 6px auto;
    cursor: pointer;
    display: block;
    height: 40px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    width: 40px;

    &--left {
      // background-image: url("../images/svg/blue-left-arrow.svg");
      background-image: url("../images/png/blue-left-arrow.png");
      left: 0;
    }

    &--right {
      // background-image: url("../images/svg/blue-right-arrow.svg");
      background-image: url("../images/png/blue-right-arrow.png");
      right: 0;
    }
  }
}

body.page-template-search {
  .product-search {
    background-color: $color-off-white;

    @include breakpoint(tablet-vert) {

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e8e8e8+45,f4f4f4+45 */
      background: $color-off-white; /* Old browsers */
      //background: -moz-linear-gradient(left, $color-off-white 64%, $color-light-grey-2 64%); /* FF3.6-15 */
      //background: -webkit-linear-gradient(left, $color-off-white 64%,$color-light-grey-2 64%); /* Chrome10-25,Safari5.1-6 */
      //background: linear-gradient(to right, $color-off-white 64%,$color-light-grey-2 64%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-off-white', endColorstr='$color-light-grey-2',GradientType=1 ); /* IE6-9 */

      .container {
        //display: flex;
        //justify-content: space-between;
        max-width: 980px;
      }
    }

    .search-bar__form {

      @include breakpoint(tablet-vert) {
        padding: 15px 30px;
      }
    }

  }
}
