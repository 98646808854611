.product-compare,
.industrial-monitors {
  padding: 45px 0;

  @include breakpoint(tablet-vert) {
    padding: 60px 0;
  }

  &__header {
    margin: 0 auto 75px;
    max-width: 985px;
    text-align: center;
  }

  &__heading {
    @include to-em(28px);
    color: $color-navy-blue;
    margin-bottom: 25px;

    @include breakpoint(tablet-vert) {
      @include to-em(40px);
    }
  }

  &__table {
    border-bottom: 1px solid rgba($dark-gray, .3);

    @include breakpoint(tablet-vert) {
      border-bottom: 0px;
    }

    &-top-row {

      border-bottom: 2px solid $very-dark-blue;
      padding: 0 30px 20px;

      display: none;

      @include breakpoint(tablet-vert) {
        display: flex;
      }

    }
  }

  &__table-label {
    color: $color-navy-blue;
  }

  &__table-item {
    display: none;

    &.active {
      display: block;

      @include breakpoint(tablet-vert) {
        display: flex;
      }
    }

  }

  &__image {
    text-align: left!important;
  }

  &__row {
    padding: 20px 0px;
    border-bottom: 1px solid rgba($dark-gray, .3);
    color: $dark-gray;

    &.start-hidden {
      display: flex;
      //height: 0;
      opacity: 0;
    }

    @include breakpoint(tablet-vert) {
      padding: 20px 30px;
    }

    &__heading {
      font-size: rem-calc(14px); // Added to get some spacing back from images.
      color: $color-navy-blue;

      @include breakpoint(tablet-vert) {
        display: flex;
        align-items: center;
      }

      > span {
        padding: 15px 0;
        display: flex;

        span {
          flex: 1 0 40%;
        }

        @include breakpoint(tablet-vert){
          padding: 0;
          display: inline-block;
        }

        &:before {
          content: attr(data-header);
          display: inline-block;
          width: 60%;
          flex: 1 0 60%;
          color: $color-navy-blue;
          font-weight: 600;

          @include breakpoint(tablet-vert){
            content: "";
            background: url("../images/png/blue-arrow-right.png") center center no-repeat;
            background-size: contain;
            display: inline-block;
            margin-right: 5px;
            width: 10px;
            height: 10px;
          }
        }
      }
    }


    &__content {
      align-items: center;
      font-size: rem-calc(14px); // Added to get some spacing back from images.

      span.content {
        padding-top: 0!important;
      }

      > div {
        padding: 15px 0;
        display: flex;

        &:before {
          content: attr(data-header);
          display: inline-block;
          width: 60%;
          flex: 1 0 60%;
          color: $color-navy-blue;
          font-weight: 600;
        }

        @include breakpoint(tablet-vert){
          padding: 0;

          &:before {
            display: none;
          }
        }
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;

      padding: 20px;

      @include breakpoint(tablet-vert) {
        padding: 0 10px 0 0;
        display: inline-block;
        width: auto;
        max-width: 100px;
      }
    }
  }

  .btn__invert {
    width: 100%;

    @include breakpoint(tablet-vert) {
      width: 158px;
      margin: 0 auto;
    }

  }

  a {
    display: block;
    transition: all 200ms;
    cursor: pointer;

    &:hover {
      background: $very-light-gray;
    }
  }

  a.product-compare--viewmore {
    margin-top: 40px;
  }

  a.product-compare--viewmore:focus {
    color: $color-navy-blue;
  }

  a.product-compare--viewmore:hover {
    background-color: $color-navy-blue;
    color: #fff;
  }
}