.main-nav {

  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 80px;
  transition: ease all 0.4s;
  z-index: 99;

  transform: translateY(calc(-100% - 80px));

  &.lang-switcher-open .menu-item {
    @include breakpoint(tablet-vert down) {
        opacity: 0;
        border-top: none;

        a {
          max-height: 0;
          padding: 0px 30px;
        }
    }
  }

  .activeNav & {
    //left: 0;
    opacity: 1;
    transform: translateY(0);
  }

  // main nav
  @include breakpoint(custom-tablet) {
    display: block;
    position: relative;
    top: auto;
    transform: none;
    opacity: 1;
  }

  ul {
    @include breakpoint(custom-tablet) {
      align-items: center;
      display: flex;
      position: static;
    }
  }

  li {

    background-color: $color-white;
    border-top: 2px solid $color-navy-blue;
    display: block;

    opacity: 0;

    transform: translateY(-50px);
    transition: all 800ms;

    .activeNav & {
      transform: translateY(0px);
      opacity: 1;

      @for $i from 1 through 10 {

        &:nth-child(#{$i}) {
          transition-delay: 500ms - ($i * 100);
        }
      }
    }

    &.search-link {
      display: none;
    }

    &:first-child {
      border-top: 0;
    }

    &.lang-switcher {
      position: relative;
      height: 30px;
      margin-left: 0px;
      padding: 0 23px;
      width: 30px;

      &.menu-item-mobile {
        display: none;
        height: auto;
        width: auto;
        padding: 0;
        transition: all 0.3s;

        &.open .lang-switcher-link::after {
          transform: scale(-1);
        }

        a.lang-switcher-link {
          position: relative;
          height: auto;
          width: auto;
          padding: 15px 30px 15px 60px;
          background-image: url('../images/svg/Globe.svg');
          border-radius: 0 !important;
          background-position: 30px center;

          &::after {
            background-image: url('../images/png/blue-select-arrow-down.png');
            content: '';
            background-size: 13px auto;
            position: absolute;
            right: 20px;
            top: 20px;
            background-repeat: no-repeat;
            width: 13px;
            height: 10px;
          }
        }

        .lang-switcher-list {
          top: calc(100% + 2px);
          left: 0;
          border-radius: 0;
          box-shadow: none;
          width: 100%;
        }

        .lang-link {
          padding: 15px 30px;
          text-align: left;
        }
      }

      @include breakpoint(tablet-vert down) {
        display: none;

        &.menu-item-mobile {
          display: block;
        }
      }

      &::before {
        content: "";
        height: 20px;
        left: 0;
        margin-top: -10px;
        position: absolute;
        top: 50%;
        width: 1px;
        background-color: rgba($color-white, 0.4);
      }

      a.lang-switcher-link { // the search loupe
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px auto;
        position: absolute;
        display: block;
        height: 30px;
        width: 30px;
        background-image: url("../images/svg/Globe-white.svg");
        padding: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        &:before {
          display: none;
        }
      }

      &.open {
        a.lang-switcher-link {
          background-image: url("../images/svg/Globe.svg");
          background-color: white;
        }

        .lang-switcher-list {
          opacity: 1;
          max-height: unset;
        }
      }


      a.lang-link {
        color: $color-navy-blue;
        padding: 5px 10px;
        margin: 0;
        text-align: center;
        text-transform: none;
        transition: background-color 300ms;

        &:after {
          position: absolute;
          content: '';
          height: 1px;
          bottom: 0;
          left: 10px;
          right: 10px;
          border-bottom: 1px solid lightgray;
        }

        &:hover {
          background-color: #f0f0f0;
          opacity: 0.5;
        }

        &:before {
          display: none;
        }

        &.active {
          color: #b20939;
          font-weight: bold;
        }

        &:last-child:after {
          border-bottom: none;
        }
      }

      .lang-switcher-list {
        max-height: 0;
        opacity: 0;
        transition: all 0.3s;
        width: 175px;
        overflow-y: hidden;
        background: white;
        position: absolute;
        padding: 0px;
        top: 30px;
        left: -122px;
        border-radius: 5px;
        border-top-right-radius: 0;
        -webkit-box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.5);
        box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.5);
      }
    }

    @include breakpoint(custom-tablet) {
      transform: translateY(0px);
      opacity: 1;
      transition: 0.3s all;

      margin-left: 20px;

      background-color: transparent;
      border-top: 0;
      display: inline-block;

      &.search-link {
        display: inline-block;
      }

      &:first-child {
        border-top: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &.search-link {
        position: relative;

        &::before {
          content: "";
          height: 20px;
          left: 0;
          margin-top: -10px;
          position: absolute;
          top: 50%;
          width: 1px;
        }

        a {
          background-position: center;
          background-repeat: no-repeat;
          //background-size: 16px auto;
          display: block;
          //height: 30px;
          width: 76px;

          background-size: contain;
          height: 16px;
        }
      }
    }

    @include breakpoint(xlarge2) {
      margin-left: 40px;
    }

    a {
      text-transform: uppercase;
      position: relative;

      color: $color-navy-blue;
      display: block;
      padding: 15px 30px;

      @include breakpoint(custom-tablet) {
        transition: all 0.3s;
        max-height: 60px;
        @include to-em(15px);
        color: #fff;
        padding: 0;
      }

    }

    &:not(.search-link){
      a {
        &:before {
          content: "";
          position: absolute;
          top: -10px;
          height: 3px;
          background: #fff;
          width: 100%;
          transform: scaleX(0);
          transform-origin: right;
          transition: transform 200ms;
        }

        &:hover {
          &:before {
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      }
    }
  }

  @include breakpoint(custom-tablet) {
    .masthead--bg & {
      a {
        color: $color-white;

      }

      li.search-link {
        a {
          background-image: url("../images/svg/white-loupe-icon.svg");
        }

        &::before {
          background-color: rgba($color-white, 0.4);
        }
      }

    }

  .masthead--small & {
    a {
      color: $dark-gray;
    }
  }

  .masthead--nobg &,
  .masthead--small &,
  .masthead--simple &,
  .masthead--blog &,
  .masthead--support & {
      li.search-link {
        &::before {
          background-color: rgba($color-dark-grey-1, 0.4);
        }

        a {
          background-image: url("../images/svg/grey-loupe-icon.svg");
        }
      }

      li.lang-switcher {
        > a {
          background-image: url("../images/svg/Globe.svg");
        }      
        
        &.open > a {
          background-image: url("../images/svg/Globe-navy.svg");
        }
      }

      a {
        color: $color-dark-grey-1;

        &:before {
          background: $color-dark-grey-1!important;
        }
      }
    }
  }
}

@include breakpoint(tablet-vert down) {
  .activeNav {
    overflow: hidden;

    &:before {
      content: "";
      position: fixed;
      background: rgba(0,0,0,.2);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

#mobile-search {
  display: none;
  appearance: none;
  background: 0 0;
  border: none;
  cursor: pointer;
  height: 50px;
  outline: 0;
  position: relative;
  transition: ease all .45s;
  vertical-align: middle;
  width: 50px;
  z-index: 80;
  background-image: url(/wp-content/themes/hopeind_foundation/dist/assets/images/svg/white-loupe-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  background-size: contain;
  height: 20px;

  @include breakpoint(medium down) {
    display: inline-block;
  }
}

.masthead--nobg, .masthead--simple, .masthead--small, .masthead--support {
  #mobile-search {
    background-image: url(/wp-content/themes/hopeind_foundation/dist/assets/images/svg/grey-loupe-icon.svg);
  }
}

#mailto-copy {
  position: absolute;
  outline: 0;
  color: transparent;
  background-color: transparent;
  appearance: none;
  border: none;
}

