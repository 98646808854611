.product-search {
  background-color: $color-off-white;
  padding: 40px 5%;

  @include breakpoint(tablet-vert) {

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e8e8e8+45,f4f4f4+45 */
    //background: $color-off-white; /* Old browsers */
    //background: -moz-linear-gradient(left, $color-off-white 64%, $color-light-grey-2 64%); /* FF3.6-15 */
    //background: -webkit-linear-gradient(left, $color-off-white 64%,$color-light-grey-2 64%); /* Chrome10-25,Safari5.1-6 */
    //background: linear-gradient(to right, $color-off-white 64%,$color-light-grey-2 64%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-off-white', endColorstr='$color-light-grey-2',GradientType=1 ); /* IE6-9 */

    .container {
      display: flex;
      justify-content: center;
      //max-width: 980px;
    }
  }

  @include breakpoint(xlarge) {
    padding: 40px 0;
  }

  .search-bar__wrapper {
    width: 100%;
    margin-bottom: 20px;

    @include breakpoint(tablet-vert) {
      margin-bottom: 0;
      max-width: 680px;
      width: 65%;

      &--full-width {
        width: 100%;
        max-width: none;
      }
    }
  }

  .search-bar__form {
    padding: 4px 16px;

    @include breakpoint(large down) {
      display: flex;
      align-items: center;
    }

    @include breakpoint(tablet-vert) {
      padding: 4px 32px;
    }
  }

  .search-bar__form-submit {
    overflow: hidden;
    height: 24px;
    width: 24px;
    text-indent: 1000%;
    background-image: url("../images/svg/grey-loupe-icon.svg");
    background-size: 19px auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__chat-button, &__contact-button {
    @include breakpoint(tablet-vert) {
      //width: 180px;
      flex: 1 0 auto;
    }

    .btn {
      @include font-sans(medium);
      border-radius: 0;
      border-width: 1px;
      width: 100%;
      padding: 15px 30px;

      font-weight: 600;

      span{
        font-weight: 300;
        font-size: .8em;
      }
    }

    p {
      @include to-em(12px);
      color: rgba($text, 0.7);
      margin: 20px 0 0;
      text-align: center;
    }

    & + div {
      margin-top: 10px;

      @include breakpoint(tablet-vert) {
        //width: 180px;
        flex: 0 1 auto;
        margin-left: 20px;
        margin-top: 0;
      }
    }
  }
}

.support-content {
  padding: 40px 0 40px;

  @include breakpoint(tablet-vert) {
    .container {
      display: flex;
    }
  }

  &__item {
    width: 100%;
    margin-top: 60px;

    &:first-child {
      margin-top: 0;
    }

    @include breakpoint(tablet-vert) {
      margin-left: 20px;
      margin-top: 0;

      &:first-child {
        margin-left: 0;
      }
    }

    ul {
      border-top: 1px solid $color-border-grey;
      padding-left: 15px;
      padding-top: 25px;

      &.u-styled-list {
        margin-bottom: 0;

        li {
          margin-bottom: 15px;
        }

        li:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &__item-heading {
    @include font-sans(bold);
    font-size: 1.5em;
    //border-bottom: 1px solid $color-border-grey;
    color: $color-brilliant-red;
    flex-grow: 1;
    //padding-bottom: 20px;
    padding-left: 35px;
    margin-bottom: 20px;
    position: relative;

    &::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: block;
      height: 20px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
    }

    &--documentation::before {
      background-image: url("../images/svg/documentation-icon.svg");
    }

    &--drivers::before {
      background-image: url("../images/svg/drivers-icon.svg");
    }

    &--policies::before {
      background-image: url("../images/svg/policies-icon.svg");
    }
  }
}

.support-certifications {
  padding-bottom: 40px;

  @include breakpoint(tablet-vert) {
    padding-bottom: 60px;
  }

  &__header {
    @include breakpoint(tablet-vert) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
  }

  &__heading {
    @include font-sans(bold);
    @include to-em(30px);
    color: $color-navy-blue;
    margin: 0;
    flex: 1 0 auto;
  }


  &__items {

    /*@include breakpoint(tablet-vert) {
      display: flex;
      flex-wrap: wrap;
    }

    @include breakpoint(xlarge2) {
      flex-wrap: nowrap;
    }*/

    .img-container {
      width: 50px;
      height: 55px;
      margin: 0 auto 15px;

      img {
        //width: 100%;
        //height: auto;
        object-fit: contain;
        width: inherit;
        height: inherit;
      }
    }
  }

  &__item {
    padding: 20px 10px 20px; //40px 35px;
    text-align: center;
    //background-color: $color-off-white;

    background-color: $color-white;
    border: 2px solid $color-off-white;
    border-radius: 5px;

    margin-top: 20px;
    transition: all 200ms;
    display: block;

    &:hover {
      //background-color: darken($color-off-white, 5%);
      background-color: rgba($color-off-white,.5);
    }

    // 2 wide on phones
    width: calc(50% - 1.875rem) !important;

    @include breakpoint(tablet-vert) {
      // 3 wide on vert tablets.
      width: calc(33% - 1.875rem) !important;
      // 2 wide is this
      // width: calc(50% - 1.875rem) !important;
    }

    @include breakpoint(large) {
      // 5-wide is this
      width: calc(20% - 1.875rem) !important;
    }
    /*&:first-child {
      margin-top: 0;
    }

    @include breakpoint(tablet-vert) {
      margin-left: percentage(((100 - (4 * 24))/3)/100); //1.33%;
      width: 48.4%;
      flex: 1 0 48.4%;

      &:nth-of-type(2n + 1) {
        margin-left: 0;
      }

      &:nth-child(-n + 2) {
        margin-top: 0;
      }
    }

    @include breakpoint(xlarge2) {
      width: auto;
      margin-top: 0;
      flex: 0 1 25%;

      &:nth-of-type(2n + 1) {
        //margin-left: 3.2%;
        margin-left: percentage(((100 - (4 * 24))/3)/100); //1.33%;
      }

      &:first-child {
        margin-left: 0;
      }
    }*/

    p {
      @include to-em(14px);
      margin-bottom: 0;
      color: $dark-gray;
    }
  }

  &__items-heading {
    @include to-em(14px);
    margin-bottom: 0px;

    span {
      color: $color-navy-blue;
      display: block;
    }

    + p {
      margin-top: 10px;
    }
  }

  &__btn {
    margin: 40px auto 0;
    padding: 34px 0;

    @include breakpoint(tablet-vert) {
      width: 400px;
      margin-top: 80px;
    }
  }

  &.4-wide {
    @include breakpoint(large) {
      max-width: calc(1220px - 250px);

      .support-certifications {
        &__item {
          // 4-wide is this
          width: calc(25% - 1.875rem) !important;
        }
      }

    }

  }

}

.support-contact {

  background: $light-gray;

  @include breakpoint(large) {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e8e8e8+45,f4f4f4+45 */
    background: $light-gray; /* Old browsers */
    background: -moz-linear-gradient(left, $light-gray 0%, $light-gray 30%, $very-light-gray 30%, $very-light-gray 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $light-gray 0%, $light-gray 30%, $very-light-gray 30%, $very-light-gray 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $light-gray 0%, $light-gray 30%, $very-light-gray 30%, $very-light-gray 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$light-gray', endColorstr='$very-light-gray',GradientType=1 ); /* IE6-9 */
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include breakpoint(large) {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e8e8e8+45,f4f4f4+45 */
      background: $light-gray; /* Old browsers */
      background: -moz-linear-gradient(left, $light-gray 0%, $light-gray 30%, $very-light-gray 30%, $very-light-gray 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, $light-gray 0%, $light-gray 30%, $very-light-gray 30%, $very-light-gray 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, $light-gray 0%, $light-gray 30%, $very-light-gray 30%, $very-light-gray 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$light-gray', endColorstr='$very-light-gray',GradientType=1 ); /* IE6-9 */
    }
  }

  &__inner-block {
    border: 1px solid $dark-gray;
    background-color: $light-gray;
    padding: 10px 10px 10px 75px;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    background-position: 15px center;
    height: 70px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-of-type {
      margin: 0 0 10px 0;
    }

    @include breakpoint(medium) {
      margin: 0;

      &:first-of-type {
        margin: 0 10px 0 0;
      }
    }

    @include breakpoint(large) {
      padding: 10px 10px 10px 90px;
      background-position: 25px center;

      &:first-of-type {
        margin: 0 0 0 20px;
      }

      margin: 0 0 0 20px;
    }

    p {
      margin-bottom: 0;
    }
  }

  &__block {
    padding: 10px 0;
    height: 100%;
    font-size: rem-calc(12px);
    text-align: center;
    flex: 1 0 auto;

    @include breakpoint(large) {
      text-align: left;
    }

    &__heading {
      font-weight: bold;
      color: $very-dark-blue;
      text-transform: uppercase;
      font-size: rem-calc(14px);
      text-align: center;

      @include breakpoint(large) {
        text-align: left;
      }

    }

    &--call {
      flex: 1 0 100%;

      @include breakpoint(large) {
        flex: 0 1 25%;
      }

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &--chat-email {
      flex: 1 0 100%;
      display: flex;
      flex-wrap: wrap;

      @include breakpoint(large) {
        background-color: $very-light-gray;
        flex: 1 0 auto;
      }

      justify-content: space-evenly;
      padding: 20px 0;
    }

    &--chat {
      background-image: url("../images/svg/icon-contact-chat.svg");
      text-align: left;

      h2 {
        margin-bottom: 2px;
        text-align: left;
      }
    }

    &--email {
      background-image: url("../images/svg/icon-contact-email.svg");
      text-align: left;

      h2 {
        margin-bottom: 2px;
        text-align: left;
      }
    }

  }

  /*&__items {
    @include breakpoint(custom-tablet) {
      border-top: 1px solid $color-border-grey;
      display: flex;
      justify-content: center;
      padding: 55px 0;
    }
  }*/

  &__item {
    color: $color-navy-blue;
    padding-left: 60px;
    position: relative;
    display: block;
    margin-top: 40px;

    &:first-child {
      margin-left: 0;
      margin-top: 0;

      &::after {
        display: none;
      }
    }

    @include breakpoint(custom-tablet) {
      margin-left: 90px;
      margin-top: 0;
    }

    @include breakpoint(xlarge) {
      margin-left: 180px;
    }

    &::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 45px;
      content: "";
      display: block;
      left: 0;
      margin-top: -22px;
      position: absolute;
      top: 50%;
      width: 45px;
      height: 45px;
    }

    &--accounts::before {
      background-image: url("../images/svg/email-accounts-icon.svg");
    }

    &--support::before {
      background-image: url("../images/svg/support-icon.svg");
    }

    &--sales::before {
      background-image: url("../images/svg/sales-icon.svg");
    }

    &::after {
      background-color: $color-border-grey;
      content: "";
      display: block;
      height: 40px;
      left: -90px;
      margin-top: -20px;
      position: absolute;
      top: 50%;
      width: 1px;

      @include breakpoint(custom-tablet) {
        left: -45px;
      }
    }

    span {
      display: block;
    }
  }

  &__item-heading {
    @include font-sans(bold);
    text-transform: uppercase;
  }

  &__item-email {
    @include to-em(12px);
  }

  &__heading {
    //margin-top: 55px;
  }

  .u-center-border {
    ul {
      max-width: 960px;
      margin-top: 40px;

      @include breakpoint(tablet-vert) {
        margin: 0 auto;
      }


      li {
        font-size: 1.25em;
        margin-bottom:5px;

        @include breakpoint(tablet-vert) {
          margin: 0;
          flex: 1 0 auto;
        }

      }
    }
  }
}

.support-table {
  display: block;
  overflow-x: auto;
  width: 100%;

  &__table {
    width: 100%;
    max-width: 100%;
  }

  &__th {
    @include to-em(14px);
    border-bottom: 2px solid $color-navy-blue;
    text-transform: uppercase;
    padding-bottom: 10px;
    text-align: center; // gets overridden
  }

  &__cell {
    @include to-em(14px);
    padding: 20px 0;
    text-align: center;

    &--padding {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__downloads, &__downloads--unversioned {
    text-align: left;
  }

  &__release {
  }

  &__driver-filesize {
    white-space: nowrap;
  }

  &__product, &__product--unversioned {
    width: 190px;
  }

  &__btn {
    .btn {
      border-radius: 0;
      padding: 8px 0;
      width: 190px;
      margin: 0 auto; 
    }
  }

  .c-accordion__content--table & {
    padding-bottom: 20px;
    padding-top: 20px;

    &__th {
      @include to-em(12px);
    }

    table {
      tbody {
        tr {
          td {
            border-right: 0;
            vertical-align: middle;
            @include to-em(12px);
          }

          &:last-of-type {
            td {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

}


