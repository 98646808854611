
/* .service-support  was removed because it was borking with the & refs */

.bulletin-points-icon {
  //background-image: url("../images/jpg/service-support-bg.jpg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  padding: 45px 0;

  @include breakpoint(tablet-vert) {
    padding: 60px 0;
  }

  &__header {
    margin: 0 auto 75px;
    max-width: 760px;
    text-align: center;
  }

  &__heading {
    //@include to-em(28px);
    @include font-sans(black);
    margin-bottom: 25px;
  }

  &__grid {
    @include breakpoint(phone-horiz-tiny) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @include breakpoint(custom-tablet) {
      flex-wrap: nowrap;
      margin: 0 auto;
      max-width: 1120px;
    }
  }

  &__grid-item {
    margin-bottom: 60px;

    padding-bottom: 80px;
    position: relative;

    @include breakpoint(phone-horiz-tiny) {
      //width: 50%;
    }

    @include breakpoint(custom-tablet) {
      margin-bottom: 0;
      //width: 25%;
      //flex-grow: 1;

      &:first-child {
        .service-support__grid-item-image::before,
        .bulletin-points__grid-item-image::before {
          display: none;
        }
      }
    }

    p {
      margin: 0 20px 0;
      font-size: .9em;
    }

    &:first-of-type {
      & > figure {
        &::before {
          display: none;
        }
      }
    }
  }

  &__grid-item-image {
    height: 95px;
    margin-bottom: 55px;

    @include breakpoint(custom-tablet) {
      position: relative;

      &::before {
        background-color: $color-white;
        content: '';
        height: 100px;
        left: 0;
        margin-top: -50px;
        position: absolute;
        top: 50%;
        width: 1px;
      }
    }

    img {
      width: auto;
      height: 100%;
      display: block;
      margin: 0 auto;
      max-width: 130px;
      max-height: 90px;
    }
  }

  .btn__invert {
    margin: 0 auto;
    width: 163px;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* .experience-innovation was removed because it was borking with the & refs */

.bulletin-points {
  //background-image: url("../images/jpg/experience-innovation-bg.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $color-white;
  padding: 45px 0;

  @include breakpoint(tablet-vert) {
    padding: 60px 0;
  }

  &__header {
    margin: 0 auto;
    max-width: 720px;
    text-align: center;
  }

  &__heading {
    //@include to-em(40px);
    @include font-sans(bold);
    margin-bottom: 25px;
  }

  &__grid {

    @include breakpoint(tablet-vert) {
      align-items: stretch;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__grid-item {
    position: relative;
    padding-left: 32px;
    margin-top: 40px;

    p {
      margin: 0;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }

    &::before {
      content: "";
      background-image: url("../images/svg/white-arrow-icon.svg");
      background-position: top center;
      background-repeat: no-repeat;
      display: block;
      height: 17px;
      left: 0;
      position: absolute;
      top: 3px;
      width: 17px;
    }

    @include breakpoint(tablet-vert) {
      width: 31.2%;
      margin-top: 55px;
      padding-left: 58px;
      &::before {
        left: 26px;
      }

      &:nth-of-type(3n+1) {
        padding-left: 32px;
        &::before {
          left: 0;
        }
      }
    }
  }
}
