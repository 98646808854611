.product-accessories {
  background-color: $color-off-white;
  padding: 40px 0 70px;


  .grid-x {
    align-items: flex-start;
    //justify-content: center;

    .sticky-container {
      display: none;

      @include breakpoint(large) {
        display: block;
      }
    }

    .accessories-wrapper {

      @include breakpoint(large) {
        padding-left: 20px;
      }

      .bottom-block-item {
        background: #fff;
        padding: 30px 30px 15px;
        margin-bottom: 20px;
        border-radius: 5px;
      }
    }
  }

  .container {
    @include breakpoint(tablet-vert) {
      display: flex;
      align-items: flex-start;
    }
  }

  &__item-wrap {
    flex-grow: 2;
    border-radius: 5px;

    @include breakpoint(tablet-vert) {
      width: 85%;
    }
  }

  &__nav {
    background-color: $color-white;
    padding: 30px 20px;
    border-radius: 5px;


    /*@include breakpoint(tablet-vert) {
      width: 15%;
      margin-right: 20px;
    }*/

    li {
      @include to-em(12px);
      @include font-sans(medium);
      line-height: 18px;
      border-top: 1px solid rgba($color-black, 0.1);
      margin-top: 24px;
      padding-top: 24px;

      &:first-child {
        border-top: 0;
        margin-top: 0;
        padding-top: 0;
      }
    }

    a {
      color: $color-navy-blue;
      position: relative;
      display: block;
      padding: 0 10px;

      &::before {
        content: "";
        width: 6px;
        height: 10px;
        background-size: contain;
        background-image: url("../images/png/list-item-arrow.png");
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        left: -6px;
        top: 3px;
      }

      &:hover,
      &--active {
        color: $color-brilliant-red;
      }
    }
  }

  &__item {
    background-color: $color-white;
    margin-top: 20px;
    padding: 30px;
    //flex-grow: 2;

    &:first-child {
      margin-top: 0;
    }
  }

  &__heading {
    @include to-em(18px);
    color: $color-brilliant-red;
    margin-bottom: 10px;
  }

  &__grid-item {
    color: $color-navy-blue;
    padding: 5px;
    text-align: center;

    &:hover {
      figure {
        //transform: scale(1.01);
        //box-shadow: 0 5px 25px 0 rgba(0,0,0,.2);
        opacity: .9;
      }

      a {
        background-color: $medium-gray;
      }
    }

    a {
      background-color: $very-light-gray;
      display: block;
      border-radius: 6px 6px 0 0;
      overflow: hidden;
      transition: all 400ms;

      span {
        &.title {
          padding: 15px 10px;
          display: block;
          font-size: .9em;
          font-weight: 500;
          color: $color-navy-blue;
          background-color: #fff;
        }
      }
    }

    figure {
      border-radius: 0;
      transition: all 400ms;
    }

    img {
      display: block;
      height: auto;
      width: 100%;
      //border-radius: 6px 6px 0 0;

    }
  }

  &__copy {
    border-top: 1px solid $color-border-grey;
    padding-top: 10px;

    p {
      font-size: .78em;
      text-align: left;
    }

    ul {
      font-size: .65em;
      list-style-position: inside;
      margin: 0;
      padding: 0 5px 20px;
      list-style: none;
      text-align: left;

      li {
        list-style: none;

        strong {
          font-size: 1.2em;
        }

        ul {
          margin: 5px 0 5px 25px;
          padding: 0;
          border: 0;
          list-style: disc;
          font-size: 1em;

          li {
            list-style: disc;
            font-size: 1em;
          }
        }
      }
    }
  }

  &__grid {
    width: 100%;
    margin-left: -5px;
    margin-right: -5px;
  }

  &__grid-item-heading {
    @include to-em(16px);
    color: $color-navy-blue;
    padding: 20px 0;
    text-align: center;
    transition: ease all 0.3s;
  }

  &__grid-image {
    background-color: $color-off-white;
    //background-origin: content-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    //min-height: 150px;
    padding-top: 50%;
    border-radius: 5px;
  }
}
