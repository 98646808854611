@mixin to-em($target, $context: $base-font) {
  font-size: ($target / $context) + 0em;
}

@mixin hide() {
  display: none;
  visibility: hidden;
}

@mixin show() {
  display: block;
  visibility: visible;
}

.hidden {
  @include hide;
}

@mixin ieOnly() {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    @content;
  }
}