.timeline {
  //background-image: url("../images/jpg/history-bg.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $color-white;
  padding: 45px 0;

  @include breakpoint(tablet-vert) {
    padding: 60px 0;
  }

  &__heading {
    @include to-em(40px);
    text-align: center;
    margin-bottom: 60px;
  }

  &__entries {
    @include clearfix();
    margin: 0 auto;
    max-width: 990px;
  }

  &__entry {
    p {
      @include to-em(13px);
      font-weight: 500;
    }

    @include breakpoint(tablet-vert down) {
      padding-left: 35%;
      margin-bottom: 60px;

      &:last-child {
        .timeline__entry-icon::before, .our-history__timeline-icon::before {
          display: none;
        }
      }
    }

    @include breakpoint(custom-tablet) {
      width: 50%;
      clear: both;

      &:nth-child(odd) {
        border-right: 2px solid $color-white;
        margin-left: 1px;
        float: left;
        text-align: right;
        padding-right: 210px;

        .timeline__entry-heading, .our-history__timeline-heading {
          &::before {
            right: -212px;
          }
        }
        .timeline__entry-icon, .our-history__timeline-icon {
          right: -95px;
        }
      }

      &:nth-child(even) {
        border-left: 2px solid $color-white;
        margin-right: 1px;
        float: right;
        padding-left: 210px;

        .timeline__entry-heading, .our-history__timeline-heading {
          &::before {
            left: -212px;
          }
        }

        .timeline__entry-icon, .our-history__timeline-icon {
          left: -95px;
        }
      }
    }

  }

  &__entry-heading {
    position: relative;
    @include to-em(20px);
    margin-bottom: 15px;


    @include breakpoint(custom-tablet) {
      display: block;

      &::before {
        content: "";
        background-color: $color-white;
        height: 2px;
        position: absolute;
        width: 84px;
        top: 50%;
        margin-top: -1px;
      }
    }
  }

  &__entry-icon {
    display: block;
    height: 65px;
    margin-top: -32.5px;
    position: absolute;
    top: 50%;
    width: 65px;

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    @include breakpoint(tablet-vert down) {
      left: -100px;

      &::before {
        content: "";
        position: absolute;
        width: 2px;
        height: 80%;
        background-color: $color-white;
        left: 50%;
        margin-left: -1px;
        top: 75px;
      }
    }
  }
}
