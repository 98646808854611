@import "flexible-content/accordions";
@import "flexible-content/block-carousel";
@import "flexible-content/bulletin-points";
@import "flexible-content/client-carousel";
@import "flexible-content/company-grid";
@import "flexible-content/masthead-hero-banner";
@import "flexible-content/open-text";

@import "flexible-content/product-compare";
@import "flexible-content/product-groups";
@import "flexible-content/product-highlight";
@import "flexible-content/product-spec";
@import "flexible-content/product-tiles";

@import "flexible-content/related-products";
@import "flexible-content/text-tiles";
@import "flexible-content/timeline";

section.flexible-content {
	position: relative;
	//z-index: 1;
}




