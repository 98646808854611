.product-spec,
.compare-specs {
  background-color: $color-light-grey-1;


  @include breakpoint(tablet-vert) {
    display: flex;
    align-items: stretch;
    justify-content: center;

    padding: 30px 5%;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e8e8e8+45,f4f4f4+45 */
    background: rgb(232,232,232); /* Old browsers */
    background: -moz-linear-gradient(left, rgba(232,232,232,1) 45%, rgba(244,244,244,1) 45%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(232,232,232,1) 45%,rgba(244,244,244,1) 45%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(232,232,232,1) 45%,rgba(244,244,244,1) 45%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e8e8', endColorstr='#f4f4f4',GradientType=1 ); /* IE6-9 */
  }

  &__description {
    padding: 45px 0 45px 5%;
    width: 90%;

    @include breakpoint(tablet-vert) {
      align-items: center;
      background-color: $color-light-grey-2;
      display: flex;
      max-width: 420px;
      padding: 45px 50px 45px 0;
      width: 25%;
      flex-grow: 1;
    }
  }


  &__description-content {
    .btn {
      width: 250px;
      margin-top: 40px;
    }

    p {
      @include to-em(15px);
      color: $very-dark-gray;
    }
  }

  &__heading {
    @include font-sans(bold);
    @include to-em(30px);
    color: $color-navy-blue;
    line-height: 1.35em;
  }

  &__sub-heading {
    @include font-sans(book);
    @include to-em(20px);
    color: $color-navy-blue;
  }

  &__detail-wrapper {
    margin-top: -45px;

    @include breakpoint(tablet-vert) {
      margin-top: 0;

      align-items: center;
      display: flex;
      flex-grow: 2;
      max-width: 800px;
      width: 75%;
    }

    @include breakpoint(xlarge) {

    }
  }

  &__image {
    /*&--desktop {
      display: none;
    }

    &--mobile {
      display: block;
    }*/

    img {
      height: auto;
      width: 100%;
    }

    @include breakpoint(tablet-vert) {
      max-width: 470px;
      //margin-left: -40px;

    }

    @include breakpoint(xlarge) {
      margin-right: 80px;
      /*
      &--desktop {
        display: block;
      }

      &--mobile {
        display: none;
      }*/
    }
  }

  &__details {
    display: none;

    @include breakpoint(tablet-vert) {
      display: block;
      max-width: 290px;
      // width: 25%;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        color: $medium-gray;
      }

      li {
        @include to-em(12px);
        margin: 0 0 18px;
        padding: 0 0 0 30px;
        position: relative;

        &::before {
          background-image: url("../images/svg/blue-arrow-icon.svg");
          background-position: center left;
          content: "";
          background-repeat: no-repeat;
          width: 17px;
          height: 17px;
          display: block;
          position: absolute;
          top: 3px;
          left: 0;
        }
      }
    }
  }
}
