.c-accordion {

  &__header {
    margin-bottom: 20px;

    @include breakpoint(tablet-vert) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  &__nav {
    @include breakpoint(tablet-vert) {
      display: flex;

      li {
        margin-right: 10px;
      }
    }
  }

  &__expand {
    position: absolute;
    top: 35px;
    right: 0;
    @include to-em(12px);
    color: $color-navy-blue;
    cursor: pointer;
    text-transform: uppercase;
    //margin-top: 20px;

    display: none;

    @include breakpoint(tablet-vert) {
      margin-left: auto;
      display: block;
    }

    @include breakpoint(xlarge) {
      margin-top: 0;
    }
  }

  &__btn {
    padding: 10px 42px;
    flex-grow: 1;
    border-radius: 0;
    margin-bottom: 10px;

    &.btn__invert {
      border-width: 1px;
    }

    @include breakpoint(tablet-vert) {
      margin-left: 20px;
      margin-bottom: 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__content {
    max-height: 0;
    //display: none;
    overflow: hidden;
    transition: ease all 0.3s;
    padding: 20px 40px 0 5%;
    //padding: 0px 40px 0 5%;

    @include breakpoint(tablet-vert) {
      padding: 20px 0 0 0;
      //padding: 0px 0 0 0;
      //margin-left: 15%;
      margin-right: 40px;
      //position: relative;
      //top: -20px;
    }

    &--table {
      padding: 0;
    }

    &--slim {
      @include breakpoint(tablet-vert) {
        padding-right: 40px;
      }
    }
  }

  &__item-heading {
    @include font-sans(bold);
    @include to-em(16px);
    color: $color-navy-blue;
    cursor: pointer;
    display: block;
    //position: relative;
    margin-bottom: 0;

    flex: 1 0 15%;

    &::before {
      background-image: url("../images/svg/faq-show-icon.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: block;
      height: 20px;
      margin-top: -8px;
      position: absolute;
      right: 0;
      top: 50%;
      width: 20px;
    }

    span {
      display: inline-block;
      //width: 15%;
      padding-right: 10px;
    }

  }



  &__item {
    border-bottom: 1px solid rgba(0,0,0,.2);
    padding: 25px 0;

    position: relative;

    &:first-of-type {
      //padding-top: 0;
    }

    &:last-of-type {
      border-bottom: 0;
    }

    p {
      font-size: rem-calc(13px);
      margin-bottom: 0;
    }

    ul, ol {
      font-size: rem-calc(13px);
    }

    &--active {
      > .c-accordion__item-heading::before {
        background-image: url("../images/svg/faq-hide-icon.svg");
        top: 35px;
      }

      > .c-accordion__content {
        max-height: none;
        display: block !important;
        //padding-bottom: 26px;
      }
    }

    // Second level nested
    .c-accordion__item .c-accordion__item-heading {
      font-size: rem-calc(14px);
    }

  }
}

.tabs {
  border: 0;
  background: transparent;

  &-title {
    transition: ease all 0.3s;
    padding-right: 0px;
    width: 100%;

    text-align: left;
    position: relative;

    &:after {
      content: "";
      background: url("../images/svg/ddl-accent-white.svg") center center no-repeat;
      background-size: contain;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 20px;
      top: calc(50% - 5px);
      transform: translateY(-50%);
      z-index: 1;
    }

    a {
      font-size: rem-calc(14px);
      padding: 15px 25px;
      color: $color-navy-blue;
      font-weight: 400;
      text-align: left;

      &:hover {
        background-color: rgba($color-navy-blue, .1);
      }
    }

    display: none;

    &.is-active {
      display: block!important;

      a, a:hover {
        background-color: $color-navy-blue;
        color: $color-white;
      }
    }

    @include breakpoint(tablet-vert){
      display: block!important;
      width: auto;
      padding-right: 10px;
      text-align: center;

      &:after {
        display: none;
      }

      a {
        text-align: center;
      }
    }
  }

  &-content {
    border: 0;
    background: transparent;
    color: $dark-gray;
  }

  &-panel {
    padding: 0;
    margin-top: 25px;
    //padding-top: 25px;
    border-top: 1px solid rgba(0,0,0,.2);

    p {
      font-size: .9em;
      line-height: 1.8;
    }

    table {
      margin-top: 20px;
    }

    &:not(.c-accordion) {
      padding-top: 25px;

      h2 {
        font-weight: 700;
        color: $color-brilliant-red;
        margin: .85em 0;
        font-size: 1.5em;
      }

      h3 {
        font-weight: 700;
        color: $color-brilliant-red;
        margin: .85em 0;
        font-size: 1.25em;
      }

      h4 {
        font-weight: bold;
        color: $dark-gray;
        margin: .5em 0;
        font-size: 1.0em;
      }

      h5 {
        font-weight: bold;
        color: $dark-gray;
        margin-bottom: 25px;
        font-size: .9em;
      }

      > * {
        &:first-child {
          margin-top: 0!important;
          padding-top: 0!important;
        }
      }
    }
  }

}
