.featured-hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: rem-calc(100);

  @include breakpoint(medium) {
    height: rem-calc(200);
  }

  @include breakpoint(xlarge) {
    height: rem-calc(400);
  }
}

