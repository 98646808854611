//general image overrides
.aligncenter, .alignnone {
  margin: 40px auto;
  float: none;
  display: table;

  &.wp-caption {

    @include breakpoint(tablet-vert){
      max-width: 540px;
    }
  }

  & > * {
    clear: both;
    display: block;
    margin-top: 40px;
  }
}

.alignleft {
  float: left;
  @include breakpoint(tablet-vert){
    margin: 0 40px 40px 0;
  }

  &.wp-caption {

    @include breakpoint(tablet-vert){
      max-width: 300px;
    }
  }
}

.alignright {
  float: right;
  @include breakpoint(tablet-vert) {
    margin: 0 0 40px 40px;
  }

  &.wp-caption {

    @include breakpoint(tablet-vert){
      max-width: 300px;
    }
  }
}

figure {
  &.wp-caption {
    width: auto;

    a {
      display: block;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .wp-caption-text {
    font-size: .7em;
    padding: 25px 15px;
    border-bottom: 1px solid $color-border-grey;
    margin: 0 0 30px;
  }
}

//wp meta box styles
.entry-content {

  overflow: hidden;

  h1 {
    @include to-em(40px);
    margin: 0 0 15px;
    padding: 0;
    color: $color-navy-blue;
  }
  
  h2 {
    @include to-em(30px);
    margin: 0 0 15px;
    padding: 0;
    color: $color-navy-blue;
  }

  h3 {
    @include to-em(25px);
    margin: 0 0 15px;
    padding: 0;
    color: $color-navy-blue;
  }

  p {
    color: $dark-gray;
    margin: 0 0 15px;
    @include to-em(16px);
    line-height: 150%;

    & + h1, & + h2, & + h3, & + h4, & + h5, & + h6 {
      margin-top: 30px;
    }
  }

  a {
    color: $color-navy-blue;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    border-radius: 8px;
  }

  .aligncenter, .alignnone {
    margin: auto auto;
    float: none;
    display: table;

    @include breakpoint(tablet-vert){
      max-width: 540px;
    }

    & > * {
      clear: both;
      display: block;
      margin-top: 40px;
    }
  }

  .alignleft {
    float: left;

    @include breakpoint(tablet-vert){
      margin: 0 40px 40px 0;
      max-width: 300px;
    }
  }

  .alignright {
    float: right;

    @include breakpoint(tablet-vert){
      margin: 0 0 40px 40px;
      max-width: 300px;
    }
  }

  ul, ol {
    margin: 0;
    padding: 0 0 15px 40px;
    @include to-em(16px);
    line-height: 150%;

    li {
      margin: 0 0 8px;
      padding: 0;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  figure {
    &.wp-caption {
      width: auto;

      a {
        display: block;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .wp-caption-text {
      font-size: .7em;
      padding: 25px 15px;
      border-bottom: 1px solid $color-border-grey;
      margin: 0 0 30px;
    }
  }

  .more-link {
    display: inline-block;
    background: $color-navy-blue;
    padding: 10px 20px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    @include to-em(12px);
    margin: 25px 0;
    text-decoration: none;
    transition: all 200ms;

    &:hover {
      background: lighten($color-navy-blue, 5%);
    }
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 40px auto;

  figure{
    &.gallery-item {
      margin: 10px;
      background: #f0f0f0;
      border-radius: 5px;
      overflow: hidden;
      border: 2px solid #ececec;
      display: flex;
      flex-direction: column;
      justify-content: center;

      a {
        display: block;
      }

      .gallery-icon {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      figcaption {
        &.gallery-caption {
          margin: 0;
          border-bottom: 0;
          background: #eaeaea;
          text-align: center;
          flex: 0 1 auto;

          h4 {
            color: $color-brilliant-red;
            margin: 0 0 5px;
            padding: 0;
            @include to-em(20px);
          }

          strong {
            color: $dark-nav-color;
            margin: 0 0 5px;
            display: block;
          }
        }
      }
    }
  }
}

@for $i from 1 through 9 {
  .gallery.gallery-columns-#{$i} {

    $w: 100% / $i;

    figure {
      &.gallery-item {
        flex: 0 1 calc(100% - 20px);
        width: calc(100% - 20px);
      }
    }
  }
}

@include breakpoint(tablet-vert) {
  @for $i from 1 through 9 {
    .gallery.gallery-columns-#{$i} {
      figure {
        &.gallery-item {
          flex: 0 1 calc(50% - 20px);
          width: calc(50% - 20px);
        }
      }
    }
  }
}

@include breakpoint(large) {
  @for $i from 1 through 9 {
    .gallery.gallery-columns-#{$i} {

      $w: 100% / $i;

      figure {
        &.gallery-item {
          flex: 0 1 calc(#{$w} - 20px);
          width: calc(#{$w} - 20px);
        }
      }
    }
  }
}
