@mixin font-sans($weight: book) {
  @if($weight == extra-light) {
    font-weight: 200;
  }

  @else if($weight == light) {
    font-weight: 300;
  }

  @else if($weight == medium) {
    font-weight: 500;
  }

  @else if($weight == bold) {
    font-weight: 700;
  }

  @else if($weight == black) {
    font-weight: 800;
  }

  @else {
    font-weight: 400;
  }
}
