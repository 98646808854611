// Page header
.front-hero {
  .marketing {
    @include xy-grid-container(55rem);
    @include xy-grid;
  }

  @include breakpoint(small) {
    background: url('../images/demo/hero-bg-foundation-6-small.svg') bottom center;
    background-size: cover;
    background-position: bottom;
    padding: rem-calc(65%) 0;
    margin: 0 0 rem-calc(32);
    height: auto;
    position: relative;
    text-align: left;
  }

  @include breakpoint(medium) {
    background: url('../images/demo/hero-bg-foundation-6-large.svg') bottom center;
    background-size: cover;
    background-position: center;
    height: rem-calc(685);
    margin: 0 0 rem-calc(72);
  }

  .watch {
    @include breakpoint(small) {
      @include xy-cell(7);
    }

    @include breakpoint(medium) {
      @include xy-cell(12);
    }

    a {
      color: #B4C9D1;
      cursor: pointer;
      font-weight: 400;
      margin-right: rem-calc(20);
    }

    a:hover {
      color: #fff;
    }

    #stargazers {
      :before {
        content: "\f09b";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        color: #B4C9D1;
        margin-right: rem-calc(8);
      }
    }

    #twitter {
      :before {
        content: "\f099";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        color: #B4C9D1;
        margin-right: rem-calc(8);
      }
    }

  }

  .tagline {
    @include breakpoint(small) {
      @include xy-cell(8);
    }

    @include breakpoint(medium) {
      @include xy-cell(5);
      padding-top: 6rem;
    }
  }

  h1 {
    color: #fff;
    font-weight: 500;

    @include breakpoint(small) {
      font-size: 2.2rem;
    }

    @include breakpoint(medium) {
      font-size: 2.875rem;
    }
  }

  h4 {
    color: #fefefe;
    font-weight: 300;
    font-size: 1.3125rem;
  }

  .download {
    margin-top: rem-calc(20);
  }
}

// Intro
.intro {
  @include xy-grid-container;
  @include xy-grid;

  .fp-intro {
    @include breakpoint(small) {
      @include xy-cell(12);
    }

    @include breakpoint(medium) {
      @include xy-cell(10);
      @include xy-cell-offset(1);

      h2 {
        font-weight: 300;
        margin-bottom: 1.5rem;
      }

      h4 {
        font-size: 1.125rem;
        line-height: 1.6;
        color: #777;
        margin-bottom: 2rem;
      }
    }
  }
}

// Section divider
.section-divider {
  @include xy-grid-container;
  @include xy-grid;

  hr {
    @include xy-cell(12);
    @extend .dotted;
    box-sizing: border-box;
  }
}

// Benefits
.benefits {
  @include xy-grid-container;
  @include xy-grid;
  text-align: center;

  header {
    @include xy-cell(12);

    h2 {
      font-weight: 300;
    }

    h4 {
      font-size: 1.125rem;
      line-height: 1.6;
      color: #777;
    }
  }

  .semantic,
  .responsive,
  .customizable,
  .professional {
    @include breakpoint(small) {
      @include xy-cell(12);
    }

    @include breakpoint(medium) {
      @include xy-cell(3);
    }

    img {
      padding: 1.25rem;
      margin: 1rem auto 0 auto;
    }

    h3 {
      color: #0a0a0a;
      font-weight: 300;
      font-size: 1.75rem;
    }

    p {
      font-size: 0.9375rem;
    }
  }

  .why-foundation {
    @include xy-cell(12);
    margin-top: 4rem;
  }
}
