nav {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
  }
}

figure {
  margin: 0;
}

.container {
  margin: 0 auto;
  max-width: $max-width;
  width: 90%;

  &.full {
    max-width: none;
    width: 95%;
  }
}

a {
  color: $color-navy-blue;
}

a:focus {
  color: currentColor;
  outline: none;
}

body {
  color: $dark-gray;
  overflow-x: hidden;
}

table {
  border: 0;
  background: transparent;
  margin: 40px 0;

  thead {
    background: transparent;
    border: 0;

    tr {
      border-bottom: 2px solid $color-navy-blue;
    }

    th, td {
      color: $color-navy-blue;
      @include to-em(10px);
      border-bottom: 2px solid $color-navy-blue;
      padding: 0 20px 20px;
      text-transform: uppercase;
    }
  }

  tbody {
    border: 0;

    tr:nth-child(even) {
      background: #fff;
    }

    td {
      padding: 20px;
      border-right: 1px solid $color-light-grey-3;
      border-bottom: 1px solid $color-light-grey-3;
      vertical-align: top;
      font-size: .9em;
      line-height: 1.8;

      &:last-of-type {
        border-right: 0;
      }
    }
  }

  tfoot {
    border: 0;
  }
}

.pagination-container {
  text-align: center;
}

.pagination {
  margin: 40px auto;
  display: inline-block;
  border: 2px solid $color-navy-blue;
  padding: 5px 8px;

  .current {
    background: $color-navy-blue;
  }
}

// Undo global Gutenberg change to empty p blocks - keep them height 0.
p:empty:before {
  content: unset;
}

