.text-tiles {
  padding: 60px 0;

  &__grid {

  }

  h2 {
    @include to-em(28px);
    color: $color-navy-blue;
    margin-bottom: 25px;

    @include breakpoint(tablet-vert) {
      @include to-em(40px);
    }
  }

  &__item {
    background-color: $color-off-white;
    border-radius: 6px;
    border: 1px solid $color-light-grey-3;
    margin-bottom: 20px;
    padding: 42px 20px 84px;
    position: relative;
    text-align: center;

    p {
      margin: 0;
      position: absolute;
      bottom: 42px;
      width: 100%;
      text-align: center;
      left: 0;
      color: $dark-gray;
    }
  }

  a.text-tiles__item {
    transition: all 200ms;

    &:hover {
      background-color: lighten($color-off-white, 3%);
    }
  }

  &__item-heading {
    @include to-em(16px);
    color: $color-navy-blue;

    &::before {
      content: "";
      width: 90px;
      height: 83px;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 auto 55px;
    }

  }

  .text-tile {
    &__cta {
      margin-top: 30px;
    }

    &__link {
      border: 2px solid $color-navy-blue;
      text-transform: uppercase;
      color: $color-navy-blue;
      padding: 15px 25px;
      transition: all 200ms;
      font-weight: 400;
      font-size: .9em;
      border-radius: 2px;

      &:hover {
        background: $color-navy-blue;
        color: $color-white;
      }
    }
  }
}
