.product-story {
  @include breakpoint(tablet-vert) {
    margin-top: -200px;
  }

  .container {
    background-color: #fff;
    overflow: hidden;
    border-radius: 8px;

    @include breakpoint(tablet-vert) {
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }
  }

  .grid-x {
    margin: 0 -10px;
  }

  &__item {
    @include to-em(14px);
    margin-bottom: 40px;
    text-align: center;

    @include breakpoint(tablet-vert) {
      //padding-left: 22px;
      padding: 0 10px;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &__image {
    display: block;
    height: auto;
    width: 100%;
  }

  p {
    margin: 15px 8px;
    color: $dark-gray;
    @include to-em(14px);
  }
}
