.client-carousel,
.company-grid,
.our-client {
  padding-bottom: 20px;
  background: #fff;

  .slick-track {
    display: flex;
    align-items: center;

    .client_logo {
      padding: 0 15px;

      img {
      	margin: auto;
        max-height: 120px;
        width: auto;
      }
    }
  }

  .slick-arrow{
    width: 17px;
    height: 17px;
    border: 2px solid $color-border-grey;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.slick-next {
      right: -50px;
      transform: translateY(-50%) rotate(45deg);
      border-width: 2px 2px 0 0px;
    }

    &.slick-prev {
      left: -50px;
      transform: translateY(-50%) rotate(-45deg);
      border-width: 2px 0 0 2px;
    }
  }

  &__company-logos {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
    }

    li {
      margin: 0;
      padding: 0;
    }

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  &__heading {
    margin-bottom: 15px;
  }

}
