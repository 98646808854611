.product-tiles {
  margin: 0 auto;
  max-width: 1220px;
  padding: 20px 0;
  width: 90%;

  &.u-round-section {
    /*@include breakpoint(tablet-vert) {
      margin-top: -200px;
      padding: 20px;
    }*/
  }

  @include breakpoint(phone-horiz-tiny) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include breakpoint(large) {
    flex-wrap: nowrap;
  }

  .grid-x {
    margin: 0 -10px;
  }

  &__item {
    @include to-em(14px);
    margin-bottom: 40px;
    text-align: center;

    @include breakpoint(phone-horiz-tiny) {
      width: 48.4%;
    }

    @include breakpoint(large) {
      //padding-left: 22px;
      padding: 0 10px;

      &:first-child {
        //padding-left: 0;
      }
    }
  }

  &__image {
    display: block;
    height: auto;
    width: 100%;
    @include breakpoint(tablet-vert) {
      max-height: 324px;
    }
  }

  p {
    margin: 15px 8px;
    color: $dark-gray;
    @include to-em(14px);
  }
}
