.products-block-details {
  background-color: $color-white;
  padding: 30px 0;
  overflow: hidden;

  .container {
    position: relative;
  }

  ol {
    margin-top: 20px;
  }

  &__accordion-content {
    &--numbered {
      counter-reset: accordion-counter;
    }

    @include breakpoint(tablet-vert) {
      //display: flex;
    }

    img {
      border-radius: 6px;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__accordion-content-col {
    @include breakpoint(tablet-vert) {
      width: 50%;
    }
  }

  &__accordion-content-col-item {
    .products-block-details__accordion-content--numbered & {
      position: relative;
      margin-bottom: 45px;

      &::before {
        @include font-sans(bold);
        background-color: $color-brilliant-red;
        border-radius: 24px;
        color: $color-white;
        content: counter(accordion-counter);
        counter-increment: accordion-counter;
        display: block;
        height: 24px;
        left: 0;
        line-height: 24px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 24px;
      }
    }
  }

  &__accordion-content-col-item-heading {
    padding-left: 35px;
  }

  &__accordion-content-col-item-list {
    margin-top: 20px;
    padding-left: 35px;

    li {
      margin-top: 10px;
    }
  }

  .compare-grid {
    margin-top: 25px;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    p:last-of-type {
      margin-bottom: 30px;
    }
  }

  .ordering-info-grid {
    margin-top: 25px;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    & + .ordering-info-grid {
      margin-top: 60px;
    }

    table {
      thead {
        tr {
          th {
            &:first-of-type {
              width: 250px;
            }
          }
        }
      }
    }
  }

  .c-accordion__item {//overide for product pages
    padding: 25px 0 5px;
  }

  .related-blogs-container {
    @include breakpoint(tablet-vert) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
  }
  .related-blog-tile {
    margin: 0 0 20px;

    @include breakpoint(tablet-vert) {
      flex: 0 1 33.3333%;
      padding: 0 10px;
    }

    a {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 3px solid $light-gray;
      border-radius: 3px;
      background: transparent;
      text-align: center;
      padding: 20px;
      transition: all 200ms;

      &:hover {
        background: rgba($color-dark-navy-blue, .05);
      }

      span {
        color: $dark-gray;
        font-size: .7em;
        display: block;
        padding-top: 10px;
      }
    }
  }


}
