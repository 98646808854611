.solutions-gallery {
  background-color: $color-off-white;
  padding-bottom: 80px;

  .mason-sizer {
    width: (1/12 * 100%);
  }

  &__header {
    padding: 40px 0 30px;

    @include breakpoint(tablet-vert) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .custom-select {
      @include to-em(15px);
      border: 1px solid $color-border-grey;
      margin-top: 0;
      background-image: url("../images/png/blue-select-arrow-down.png");
      background-position: 90% center;
      background-size: 13px auto;
      background-repeat: no-repeat;

      select {
        color: $color-navy-blue;
        padding: 12px 75px 10px 15px;
      }
    }
  }

  &__nav {
    @include breakpoint(large down) {
      margin-bottom: 20px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      @include to-em(14px);
      text-transform: uppercase;
      margin-right: 10px;

      &:first-child {
        margin-right: 15px;
      }

      @include breakpoint(tablet-vert) {
        margin-right: 30px;
      }
    }

    a {
      color: $color-navy-blue;
      position: relative;
      padding-right: 30px;

      &::before {
        content: "";
        width: 16px;
        height: 16px;
        display: block;
        right: 0;
        top: 50%;
        margin-top: -8px;
        position: absolute;
        background-image: url("../images/svg/blue-close-icon.svg");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  &__grid {
    margin-left: -10px;
    margin-right: -10px;
  }

  &__grid-image {
    background-image: none;
    box-sizing: border-box;

    img {
      display: block;
      height: auto;
      width: 100%;
    }

    // Kicks in at tablet
    @include breakpoint(tablet-vert) {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      img {
        display: none;
      }
    }
  }

  &__grid-content {
    padding: 0 10px 0;
  }

  &__grid-item {
    //border: 20px solid #333; // should be $color-white but darkened for contrast
    padding: 0px 10px 20px;
    height: 620px;
    box-sizing: border-box;

    .grid-item-content {
      background-color: $color-white;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 20px;
    }

    &.quarter {
      width: 25%;
    }

    &.third {
      width: 33.3%;
    }

    &.half {
      width: 50%;
    }

    &.two-third {
      width: 66.6%;
    }

    &.three-quarter {
      width: 75%;
    }

    &.full {
      width: 100%;
    }

    p {
      font-size: rem-calc(13px);
    }

    @include breakpoint(tablet-vert) {

      .solutions-gallery__grid-image {
          //height: 335px; // was 225
      }

      // When we are showing two-thirds, three-quarter, and full, change the order of internals so the image is on the right.
      &.rightside {

        .solutions-gallery__grid-content {
          flex-grow: 1;
          width: 38%;
        }

        .solutions-gallery__grid-image {
          flex-grow: 2;
          height: auto;
          margin-left: 20px;
          order: 2;
          width: calc(53% - 20px);
        }
      }
    }

    @include breakpoint(xlarge) {
      &.topside {
        //width: calc(40% - 20px);

        .solutions-gallery__grid-image {
          padding-top: 70%;
        }
      }

      &.two-third {
        //width: calc(58% - 20px);

        .solutions-gallery__grid-image {
          height: auto;
        }
      }

      &.rightside {
        @include breakpoint(xlarge) {
          display: flex;
          justify-content: space-between;

          .grid-item-content {
            display: flex;
            justify-content: space-between;
          }

          .solutions-gallery__grid-content {
            flex-grow: 1;
            width: 38%;
          }

          .solutions-gallery__grid-image {
            flex-grow: 2;
            margin-left: 20px;
            order: 2;
            width: 53%;
          }
        }
      }

      &.full {
        .solutions-gallery__grid-content {
          width: 22%;
        }

        .solutions-gallery__grid-image {
          width: calc(78% - 20px);
        }
      }
    }
  }

  &__grid-heading {
    @include to-em(18px);
    color: $color-brilliant-red;
    margin-top: 20px;
  }

  &__grid-build {
    border-top: 1px solid rgba($color-border-grey, 0.2);
    padding-top: 15px;

    ul {
      margin: 15px 10px 0;
    }

    p {
      @include to-em(14px);
      font-weight: bold;
      margin: 0;
    }
  }
}
