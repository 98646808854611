body.blog, body.archive { //blog landing
  aside {
    h3 {
      color: $color-brilliant-red;
      @include to-em(14px);
      border-bottom: 1px solid $color-border-grey;
      margin: 0 0 20px 0;
      padding: 0 0 10px 0;
      text-transform: uppercase;
    }

    li {
      a {
        span {
          float: right;
          color: $dark-gray;
        }
      }
    }
  }

  .main-grid {
    .breadcrumbs__nav {
      margin-bottom: 25px;
      padding-bottom: 25px;
      //width: 100%;
      //border-bottom: 1px solid $color-dark-navy-blue;
    }

    .blog-landing-item {
      border-bottom: 1px solid $color-border-grey;
      padding-bottom: 50px;
      margin-bottom: 50px;
      clear: both;

      article {
        header {
          margin-bottom: 20px;

          a {
            color: $color-navy-blue;
          }

          h2 {
            @include to-em(25px);
            margin: 0;
            padding: 0;
            color: $color-navy-blue;
          }

          time {
            font-size: .75em;
            display: inline-block;
          }

          p.byline {
            font-size: .75em;
            display: block;

            @include breakpoint(tablet-vert) {
              display: inline-block;
            }

            &:before {
              content: "|";
              display: none;
              padding: 0 15px;

              @include breakpoint(tablet-vert) {
                display: inline-block;
              }
            }
          }
        }

        .entry-content {
          overflow: visible;
          img, figure, h1, h2,h3,h4,h5,h6,ul {
            display: none;
          }
        }

        .blog-thumbnail {
          width: 100%;

          //padding-top: 50%;
          margin: 0 0 20px 0;
          float: right;


          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 5px;
          background-position: center center;

          @include breakpoint(tablet-vert) {
            max-width: 300px;
            margin: 0 0 30px 30px;
            //padding-top: 20%;
          }

          img {
            display: block;
          }
        }

        & + article {
          margin-top: 50px;
          padding-top: 50px;
          border-top: 1px solid $color-border-grey;
        }

      }
    }
  }
}

body.single-post {
  .back-to-link {
    @include to-em(12px);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 25px;

    a {
      color: $color-navy-blue;

      &:before {
        content: "←";
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  .single-post-container {
    background: $light-gray;
    padding-top: 40px;
    padding-bottom: 60px;
    .main-grid {
      margin: 0;
    }

    header {
      margin-bottom: 20px;

      h1 {
        @include to-em(40px);
        line-height: 120%;
        color: $color-navy-blue;
      }

      time {
        font-size: .75em;
        display: inline-block;
      }

      p.byline {
        font-size: .75em;
        display: block;

        @include breakpoint(tablet-vert){
          display: inline-block;
        }

        &:before {
          content: "|";
          display: none;
          padding: 0 15px;

          @include breakpoint(tablet-vert){
            display: inline-block;
          }
        }
      }
    }
  }

  aside {
    margin-top: 60px;
    font-size: .85em;

    display: none;
    @include breakpoint(tablet-vert) {
      display: block;
    }

    @include breakpoint(large) {
      margin-top: 0;
    }

    div {
      background: #fff;
      padding: 20px;
      border-radius: 8px;
    }

    h3 {
      color: $color-brilliant-red;
      @include to-em(14px);
      border-bottom: 1px solid $color-border-grey;
      margin: 0 0 20px 0;
      padding: 0 0 10px 0;
      text-transform: uppercase;
    }

    ul {
      margin-bottom: 0;

      li {
        font-size: .85em;

        &:last-of-type {
          margin-bottom: 0;
        }
        a {
          span {
            float: right;
            color: $dark-gray;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .comments-container {
    margin-top: 60px;
    section#comments {
      h3 {
        @include to-em(20px);
        border-top: 2px solid $color-navy-blue;
        padding: 15px 0 0 0;
        margin: 0 0 20px 0;
        color: $color-brilliant-red;
      }

      ol.comment-list {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin: 0 0 25px;
          padding: 0 0 25px;
          border-bottom: 1px solid $color-border-grey;
          list-style: none;

          .comment-body {
            .comment-author {
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                flex: 0 1 40px;
              }

              .author-meta {
                flex: 1 0 auto;
                padding-left: 15px;
                font-size: .8em;

                cite {
                  font-size: 1em;
                  display: inline;
                  font-style: normal;
                  padding: 0 5px;

                  &:before {
                    display: none;
                  }

                  a {
                    color: $color-navy-blue;
                  }
                }
                time {
                  font-size: 1em;
                  display: inline;
                  padding: 0 5px;

                  a {
                    color: $color-navy-blue;
                  }
                }
              }
            }

            section.comment {
              padding-left: 60px;

              p {}
            }

            .reply {
              padding-left: 60px;
              font-size: .6em;
              text-transform: uppercase;

              a {
                border: 1px solid $color-navy-blue;
                padding: 5px 10px;
                display: inline-block;
                color: $color-navy-blue;
                transition: all 200ms;

                &:hover {
                  color: #fff;
                  background: $color-navy-blue;
                }
              }
            }
          }

          .children {
            margin: 30px 0 0 60px;

            li {
              &:last-child {
                border: none;
                margin: 0;
                padding: 0;
              }
            }
          }

        }
      }
    }

    section#respond {
      h3 {
        @include to-em(20px);
        padding: 0;
        margin: 0 0 20px 0;
        color: $color-brilliant-red;
        border: none;
      }

      p.cancel-comment-reply {}

      #commentform {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        p {//this is like a field group
          flex: 1 0 50%;
          padding: 0 10px;

          &.col-12 {
            flex: 1 0 100%;
          }

          &#allowed_tags {
            flex: 1 0 100%;
            display: none;
          }

          &.submit-row {
            flex: 0 1 auto;
          }

          label {
            display: block;
            font-size: .7em;
            margin-bottom: 10px;
            text-transform: uppercase;
            color: $color-navy-blue;
          }

          input {
            width: 100%;
            padding: 20px 25px;
            font-size: .9em;
            color: #000;
            border: 1px solid $color-border-grey;

            &[type=submit] {
              color: $color-white;
              border: 0;
            }
          }

          textarea {
            width: 100%;
            padding: 20px 25px;
            font-size: .9em;
            height: 150px;
            color: #000;
            border: 1px solid $color-border-grey;
          }
        }

        p.allowed_tags {
          code {}
        }
      }
    }
  }
}
