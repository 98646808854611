.block-carousel {
  	padding: 45px 0;
  	position: relative;

	@include breakpoint(medium) { // was mappy-bp(tablet-vert)
		padding: 60px 0;
	}

	&__heading {
		font-size: rem-calc(30px); // was to-em(30px);
		color: $color-navy-blue;
		text-align: center;

		@include breakpoint(medium) { // was mappy-bp(tablet-vert)
			font-size: rem-calc(40px); // was to-em(40px);
		}
	}

	&__carousel {
		margin-top: 70px;
	}

  &__carousel-item {
    border: 2px solid $text;
    margin: 45px auto 0;
    padding: 65px 10% 30px;
    position: relative;
    width: 288px;



    @include breakpoint(medium) { // was mappy-bp(tablet-vert)
      padding: 105px 90px 70px;
      width: 600px;
    }

    @include breakpoint(xlarge) { // was mappy-bp(laptop)
      padding: 105px 90px 70px;
      width: 780px;
    }
  }

	&__carousel-item.has-icon::before {
	  content: "";
	  position: absolute;
	  top: -45px;
	  left: 50%;
	  margin-left: -60px;
	  height: 90px;
	  width: 120px;
	  //background-image: url("../images/svg/corporate-goals-icon.svg");
	  background-position: center;
	  background-color: $color-white;
	  background-size: contain;
	  display: block;
	  background-repeat: no-repeat;
	}

  &__carousel-pagination {
    position: absolute;
    width: 928px;
    //border: 1px solid red;
    margin: 0 auto;
    height: 48px;
    top: 60%;
    left: 50%;
    margin-left: -464px;
    display: none;

    @include breakpoint(medium) {
      display: block;
    }

  }

  .slick-slide {
    margin: 0 15px;
    opacity: .3;
    transform: scale(0.9);
    transition: ease all 0.3s;
    width: 288px;

    @include breakpoint(medium) {
      width: 600px;
    }

    @include breakpoint(xlarge) {
      margin: 0 60px;
      width: 780px;
    }
  }

  .slick-current {
    opacity: 1;
    transform: scale(1);
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -24px;
    height: 48px;
    width: 26px;
    background-position: center;
    background-repeat: no-repeat;
    text-indent: 10000%;
    overflow: hidden;
    background-size: contain;
    transition: all 200ms;
    cursor: pointer;

    &.slick-prev {
      background-image: url("../images/png/left-arrow@3x.png");
      left: 0;

      &:hover {
        transform: translateX(-10px);
      }
    }

    &.slick-next {
      background-image: url("../images/png/right-arrow@3x.png");
      right: 0;

      &:hover {
        transform: translateX(10px);
      }
    }
  }

  p {
    font-size: rem-calc(20px);
    color: $color-brilliant-red;
    text-align: center;
  }
}

/*
.corporate-goals {
  padding: 45px 0;
  position: relative;

  @include breakpoint(tablet-vert) {
    padding: 90px 0;
  }

  &__heading {
    @include to-em(30px);
    color: $color-navy-blue;
    text-align: center;

    @include breakpoint(tablet-vert) {
      @include to-em(40px);
    }
  }

  &__carousel {
    margin-top: 70px;
  }

  &__carousel-item {
    border: 2px solid $text;
    margin: 45px auto 0;
    padding: 65px 10% 30px;
    position: relative;
    width: 288px;

    &::before {
      content: "";
      position: absolute;
      top: -45px;
      left: 50%;
      margin-left: -60px;
      height: 90px;
      width: 120px;
      background-image: url("../images/svg/corporate-goals-icon.svg");
      background-position: center;
      background-color: $color-white;
      background-size: contain;
      display: block;
      background-repeat: no-repeat;
    }

    @include breakpoint(tablet-vert) {
      padding: 105px 90px 70px;
      width: 600px;
    }

    @include breakpoint(xlarge) {
      width: 780px;
    }
  }

  &__carousel-pagination {
    position: absolute;
    width: 928px;
    border: 1px solid red;
    margin: 0 auto;
    height: 48px;
    top: 60%;
    left: 50%;
    margin-left: -464px;
    display: none;
  }

  .slick-slide {
    margin: 0 15px;
    opacity: .3;
    transform: scale(0.9);
    transition: ease all 0.3s;
    width: 288px;

    @include breakpoint(tablet-vert) {
      width: 600px;
    }

    @include breakpoint(xlarge) {
      margin: 0 60px;
      width: 780px;
    }
  }

  .slick-current {
    opacity: 1;
    transform: scale(1);
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -24px;
    height: 48px;
    width: 26px;
    background-position: center;
    background-repeat: no-repeat;
    text-indent: 10000%;
    overflow: hidden;
    background-size: contain;

    &.slick-prev {
      background-image: url("../images/svg/left-arrow@3x.png");
      left: 0;
    }

    &.slick-next {
      background-image: url("../images/svg/right-arrow@3x.png");
      right: 0;
    }
  }

  p {
    @include to-em(20px);
    color: $color-brilliant-red;
    text-align: center;
  }
}*/