body.page-template div.gform_wrapper form.config-form ul.gform_fields li.gfield  {
 .gchoice {
    input {
        width: auto !important;
        min-height: unset;
        margin-top: 0;
    }
    label {
        margin-top: 0;
    }
 }
 &.field_description_above {
    .gfield_description {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 10px;
    }
 }
 &.units-requested {
    .instruction { display: none; } // hide number range, it's superfluous
    .gfield_description { padding-top: 0; }
 }   

 &.rfq-location textarea {
    height: unset;
    resize: vertical;
 }
}