.footer-container {
  background: $color-navy-blue;
  @include breakpoint(large) {
    background: linear-gradient(to right, $color-navy-blue, $color-navy-blue 50%, $color-dark-navy-blue 50%, $color-dark-navy-blue 100%);
  }
  position: relative;
  z-index: 1;
}

.main-footer {
  background-color: $color-navy-blue;
  letter-spacing: .05em;
  width: 90%;
  max-width: 1220px;
  margin: 0 auto;

  @include breakpoint(large) {
    align-items: stretch;
    display: flex;
    justify-content: center;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#234a71+62,234a71+62,204468+65,204468+65 */
    background: rgb(35,74,113); /* Old browsers */
    background: -moz-linear-gradient(left, rgba(35,74,113,1) 70%, rgba(35,74,113,1) 70%, rgba(32,68,104,1) 70%, rgba(32,68,104,1) 70%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(35,74,113,1) 70%,rgba(35,74,113,1) 70%,rgba(32,68,104,1) 70%,rgba(32,68,104,1) 70%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(35,74,113,1) 70%,rgba(35,74,113,1) 70%,rgba(32,68,104,1) 70%,rgba(32,68,104,1) 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#234a71', endColorstr='#204468',GradientType=1 ); /* IE6-9 */
  }

  &__heading {

    @include to-em(16px);

    a {
      font-size: 12px;
      line-height: 14px;
      padding: 20px 40px;
      display: block;
    }

    @include breakpoint(large) {
      @include to-em(14px);
      @include font-sans(bold);
      text-transform: uppercase;

      a {
        padding: 0;
      }
    }

    a {
      color: $color-nice-blue;
      text-decoration: none;
    }
  }

  &__nav {
    padding: 20px 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .award-col { // 58.5px from the color change
      flex-basis: 125px;
      margin: 0px 53.5px 0px 3.5px;
      img {
        width: 100%;
      }

      p {
        color: white;
        letter-spacing: 0px;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        margin-top: 27px;
      }
    }

    @include breakpoint(large) {
      //max-width: 970px;
      padding: 100px 0;
      width: 81%;
      gap: 28px;
    }
  }

  &__nav-wrap {
    flex-grow: 1;
    gap: 10px;

    @include breakpoint(large) {
      display: flex;
      justify-content: space-between;
      gap: 64px;
    }

    > li {
      @include font-sans(bold);
      flex-grow: 1;

      /*
      &:first-of-type {
        flex-basis: 184px;
      }
      &:nth-of-type(2) {
        flex-basis: 92px;
      }
      &:last-of-type {
        flex-basis: 120px;
      }
      */
      
      > a {

        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1px;
        display: block;
        padding: 20px 40px;
        color: $color-nice-blue;

        @include breakpoint(large) {
          text-decoration: none;
          text-transform: uppercase;

          padding: 0;
        }
      }

      > ul {
        display: none;

        @include breakpoint(large) {
          display: block;
        }
      }

      > ul li {
        @include font-sans(book);
        margin-top: 18px;

        a {
          color: $color-white;
          text-transform: uppercase;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.3px;
        }
      }
    }


  }

  &__col {
    // padding: 0 40px;
  }

  .company-info {
    background-color: $color-dark-navy-blue;
    display: none;

    p {
      @include to-em(11px);
      line-height: 13px;
      color: $color-white;
      line-height: 1.666666667;
    }

    @include breakpoint(large) {
      display: block;
      padding: 100px 60px;
      width: 33.3%;
      max-width: 470px;
    }
  }

  .social-info {
    display: inline-block;
    list-style: none;
    margin: 10px 0 0 0;
    padding: 25px 0 0 0;
    border-top: 1px solid rgba(#fff,.2);

    li {
      margin: 0;
      padding: 0 20px 0 0;
      display: inline-block;
      list-style: none;

      &:last-of-type {
        padding: 0;
      }

      a {

        img {
          width: auto;
          height: 22px;
          opacity: .6;
          transition: all 200ms;
        }

        &:hover {
          img {
            opacity: 1;
          }
        }
      }
    }
  }

}

.disclaimer {
  padding: 32px 0;
  background-color: $color-white;

  ul {
    text-align: center;
  }

  li {
    @include to-em(12px);
    color: $color-navy-blue;
    display: inline-block;

    &:first-of-type {
      display: block;
    }

    &:last-child {
      border-left: 1px solid rgba($color-navy-blue, .5);
      margin-left: 12px;
      padding-left: 12px;
    }

    &.disclaimer__city {
      display: block;
    }
  }

  a {
    color: $color-navy-blue;
  }

  @include breakpoint(large) {
    ul {
      display: flex;
      justify-content: center;
    }

    li {
      margin-left: 12px;
      padding-left: 12px;
      border-left: 1px solid rgba($color-navy-blue, .5);

      &:first-of-type {
        display: inline-block;
      }

      &:first-child {
        border-left: 0;
        padding-left: 0;
        margin-left: 0;
      }
    }
  }
}
