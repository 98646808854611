.no-map {
  @include breakpoint(custom-tablet) {
    display: flex;
  }
}

.contact-info {
  padding: 45px 0;

  @include breakpoint(custom-tablet) {
    padding: 45px 56px;

    display: flex;
    justify-content: space-between;
  }

  &__block {

    border-bottom: 1px solid $color-border-grey;
    margin-bottom: 20px;

    @include breakpoint(custom-tablet) {
      border-right: 1px solid $color-border-grey;
      border-bottom: none;
      margin-bottom: 0;
      padding: 0 30px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }

  &__heading {
    text-transform: uppercase;
    font-weight: bold;
    color: $dark-pink;
    font-size: rem-calc(16px);
    padding-bottom: 5px;
  }

  &__chat-us {
    flex-grow: 1;
    .contact-info__content {
      background-image: url("../images/svg/icon-contact-chat.svg");
    }
  }

  &__call-us {
    flex-grow: 1;
    .contact-info__content {
      background-image: url("../images/svg/icon-contact-call.svg");
    }
  }

  &__email-us {
    flex-grow: 1;

    .contact-info__content {
      background-image: url("../images/svg/icon-contact-email.svg");
    }
  }

  &__mail-us {
    flex-grow: 1;

    .contact-info__content {
      background-image: url("../images/svg/icon-contact-mail.svg");
    }
  }

  &__content {
    margin-bottom: 10px;
    padding-left: 75px;
    padding-top: 0;
    color: $very-dark-gray;
    background-repeat: no-repeat;
    background-size: 50px;
    font-size: rem-calc(14px);

    @include breakpoint(custom-tablet) {
      padding-top: 75px;
      padding-left: 0;
    }

    @include breakpoint(xlarge) {
      padding-left: 75px;
      padding-top: 0;
    }

    p {
      line-height: 175%;
    }

  }


  /*&__emails {
    flex-grow: 2;
  }

  &__email-item {
    color: $color-navy-blue;
    text-align: center;
    margin-bottom: 60px;

    @include breakpoint(tablet-vert) {
      flex: 1 0 auto;
      //margin-left: 65px;
      margin-bottom: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    a {
      @include to-em(12px);
      color: $color-navy-blue;
    }
  }

  &__email-item-heading {
    @include font-sans(medium);
    @include to-em(14px);
    text-transform: uppercase;

    &::before {
      content: "";
      display: block;
      margin: 0 auto 40px;
      height: 70px;
      width: auto;
      background-position: top center;
      background-repeat: no-repeat;
    }

    &--email::before {
      background-image: url("../images/svg/email-accounts-icon.svg");
    }

    &--support::before {
      background-image: url("../images/svg/support-icon.svg");
    }

    &--sales::before {
      background-image: url("../images/svg/sales-icon.svg");
    }
  }

  &__phone-chat {
    @include to-em(15px);
    text-align: center;

    @include breakpoint(tablet-vert) {
      border-top: 1px solid $color-border-grey;
      margin-top: 35px;
      padding-top: 35px;
    }

    @include breakpoint(xlarge2) {
      border-left: 1px solid $color-border-grey;
      border-top: 0;
      flex-grow: 1;
      margin-left: 45px;
      margin-top: 0;
      padding-left: 45px;
      padding-top: 0;
      text-align: left;
    }
  }

  &__phone-chat-btns {
    margin-top: 40px;

    .btn {
      @include font-sans(medium);
      @include to-em(13px);
      border-radius: 0;
      margin-top: 12px;
      padding: 10px 20px;
    }

    @include breakpoint(tablet-vert) {
      display: flex;
      justify-content: center;

      .btn {
        margin-left: 10px;
        margin-top: 0;
        flex: 1 0 auto;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    @include breakpoint(xlarge2) {
      justify-content: space-between;
    }
  }*/

  .no-map & {
    @include breakpoint(custom-tablet) {
      padding: 0 60px 0 0;

      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    .contact-info__block {
      flex: 0 1 auto;
      padding: 0 0 40px;
      border: 0;
    }
  }
}

.form-map {
  padding-bottom: 45px;
  position: relative;
  z-index: 99;
  background-color: #fff;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;

    @include breakpoint(custom-tablet) {
      flex-wrap: nowrap;
      padding: 0 20px;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }


  &__map {
    width: 100%;
    margin-bottom: 60px;
    height: 325px;

    @include breakpoint(custom-tablet) {
      flex-grow: 1;
      margin-left: 20px;
      order: 2;
      width: 50%;
      height: auto;
    }
  }

  &__form {
    width: 100%;

    @include breakpoint(custom-tablet) {
      flex-grow: 1;
      order: 1;
      width: 50%;
      min-height: 635px;
    }

    //.acf-field {
    //  width: 100%!important;
    //}
    //
    //@include breakpoint(medium){
    //  .acf-field {
    //    width: 50%!important;
    //  }
    //}

    .contact-info__heading {
      padding: 0 0 20px 20px;
    }
  }

  .acf-field {
    position: relative;

    .acf-label {
      position: absolute;
      top: 5px;
      left: 31px;
      z-index: 1;
      font-size: .65em;
      opacity: 0;
      transition: all 200ms;

      label {
        font-weight: 200;
      }
    }
  }

  #message.updated {
    border: 1px solid #8bc34a;
    padding: 10px;
    margin: 0 5px 10px;
    text-align: center;
    background: rgba(139, 195, 74, 0.1);
    text-transform: uppercase;

    p {
      margin: 0;
      padding: 0;
      font-size: .8em;
      line-height: 1.2em;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .no-map & {
    //flex: 1 0 auto;

    .contact-info__heading {
      padding: 0 0 20px 25px;
    }
  }
}

.global-sites {
  padding-bottom: 70px;
  position: relative;
  z-index: 1;

  &__sites {
    display: flex;
    flex-wrap: wrap;
  }

  &__site {
    @include to-em(12px);
    color: $color-navy-blue;
    text-align: center;
    display: block;
    margin-bottom: 30px;
    flex: 1 0 50%;

    @include breakpoint(tablet-vert) {
      flex: 1 0 33%;
    }

    @include breakpoint(large) {
      flex: 1 0 auto;
    }

    &:first-child .global-sites__site-image {
      border: 0;

      &::before {
        display: none;
      }
    }
  }

  &__site-image {
    padding: 0 18%;

    img {
      display: block;
      height: auto;
      margin: 0 auto 35px;
      width: 100%;
      max-width: 230px;
    }

    @include breakpoint(large) {
      border-left: 1px solid $color-border-grey;

      img {
        width: 68px;
      }
    }
  }
}

.acf-fields {
  & > .acf-field {
    border: 0!important;
    padding: 0 5px 15px!important;

    &:first-child {
      border: 0!important;
    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    input[type="search"],
    input[type="email"],
    input[type="url"],
    textarea,
    select {
      padding: 20px 25px;
      min-height: 60px;
      background-color: #fff;
      border: 1px solid $dark-gray;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url("../images/svg/ddl-accent.svg");
      background-position: calc(100% - 15px) center;
      background-size: 15px;
      background-repeat: no-repeat;

      color: $dark-gray;
    }
  }
}

.acf-form-submit {
  padding: 0 5px;

  input[type="submit"].button {
    background: $color-brilliant-red;
    text-transform: uppercase;

    @include to-em(15px);
    border-radius: 3px;
    color: $color-white;
    cursor: pointer;
    display: block;
    font-weight: bold;
    padding: 12px 25px;
    text-align: center;
    transition: all 200ms;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-bottom: 0px;

    &:after {
      display: block;
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: -moz-linear-gradient(top, #c5466a 0%, #b20939 100%);
      background: -webkit-linear-gradient(top, #c5466a 0%, #b20939 100%);
      background: linear-gradient(to bottom, #c5466a 0%, #b20939 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(
                      startColorstr="#c5466a",
                      endColorstr="#b20939",
                      GradientType=0
      );

      opacity: 1;
      transition: all 200ms;
    }

    @include breakpoint(tablet-vert) {
      &:hover {
        //background: $color-brilliant-red;
        color: $color-white;

        &:after {
          opacity: 0;
        }
      }
    }
  }
}
