.product-groups {
  &__wrapper {
    padding: 40px 0;

    @include breakpoint(tablet-vert) {
      padding: 70px 0;
    }
  }

  
  &__heading {
    color: $color-navy-blue;
    margin-bottom: 40px;
    text-align: center;

    @include breakpoint(tablet-vert) {
      @include to-em(40px);
      margin-bottom: 70px;
    }
  }

  &__grid {
    /*@include breakpoint(tablet-vert) {
      display: flex;
      justify-content: space-between;
    }*/
  }

  &__grid-item {
    text-align: center;
    /*flex-grow: 1;*/

    @include breakpoint(large down) {
      margin-top: 60px;

      &:first-child {
        margin-top: 0;
      }
    }

    /*@include breakpoint(tablet-vert) {
      width: 31.2%;
      margin-left: 3.2%;

      &:first-child {
        margin-left: 0;
      }
    }*/

    /*@include breakpoint(xlarge) {
      width: 33%;
      margin-left: 100px;
    }*/
  }

  &__grid-item-heading {
    color: $color-navy-blue;

    @include breakpoint(tablet-vert) {
      @include to-em(20px);
    }
  }

  &__grid-item-image {
    margin-bottom: 25px;

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  &__btn {
    margin: 24px auto 0;
    max-width: 300px;
  }

}


.related-links {
  &__heading {
    span {
      background-color: $color-off-white;
    }
  }

  &__grid {
    margin-bottom: 65px;

    @include breakpoint(phone-horiz-tiny) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include breakpoint(custom-tablet) {
      flex-wrap: nowrap;
    }
  }

  &__grid-item {
    text-align: center;
    color: $color-navy-blue;
    margin-top: 20px;
    padding: 0 5px;
    border-radius: 3px;
    font-size: .9em;

    a {
      background-color: $color-white;
      padding: 50px 10px;
      display: block;
      border-radius: 3px;
    }

    /*@include breakpoint(phone-horiz-tiny) {
      width: 48.4%;
      margin-left: 3.2%;
      flex-grow: 1;

      &:nth-of-type(2n+1) {
        margin-left: 0;
      }
    }*/

    @include breakpoint(custom-tablet) {
      margin-top: 0;
      width: auto;

      /*&:nth-of-type(2n+1) {
        margin-left: 3.2%;
      }

      &:first-child {
        margin-left: 0;
      }*/
    }
  }
}




// #TODO: Delete when static usage is no longer needed.
.seo-accessories {
  padding: 40px 0;

  @include breakpoint(tablet-vert) {
    padding: 70px 0;
  }

  &__heading {
    color: $color-navy-blue;
    margin-bottom: 40px;
    text-align: center;

    @include breakpoint(tablet-vert) {
      @include to-em(40px);
      margin-bottom: 70px;
    }
  }

  &__grid {
    @include breakpoint(tablet-vert) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__grid-item {
    text-align: center;
    flex-grow: 1;

    @include breakpoint(large down) {
      margin-top: 60px;

      &:first-child {
        margin-top: 0;
      }
    }

    @include breakpoint(tablet-vert) {
      width: 31.2%;
      margin-left: 3.2%;

      &:first-child {
        margin-left: 0;
      }
    }

    @include breakpoint(xlarge) {
      width: 33%;
      margin-left: 100px;
    }
  }

  &__grid-item-heading {
    color: $color-navy-blue;

    @include breakpoint(tablet-vert) {
      @include to-em(20px);
    }
  }

  &__grid-item-image {
    margin-bottom: 25px;

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  &__btn {
    margin: 24px auto 0;
    max-width: 300px;
  }
}

.rated-accessories {
  &__heading {
    span {
      background-color: $color-off-white;
    }
  }

  &__grid {
    margin-bottom: 65px;

    @include breakpoint(phone-horiz-tiny) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include breakpoint(custom-tablet) {
      flex-wrap: nowrap;
    }
  }

  &__grid-item {
    text-align: center;
    background-color: $color-white;
    color: $color-navy-blue;
    padding: 50px 10px;
    margin-top: 20px;

    @include breakpoint(phone-horiz-tiny) {
      width: 48.4%;
      margin-left: 3.2%;
      flex-grow: 1;

      &:nth-of-type(2n+1) {
        margin-left: 0;
      }
    }

    @include breakpoint(custom-tablet) {
      margin-top: 0;
      width: auto;

      &:nth-of-type(2n+1) {
        margin-left: 3.2%;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
