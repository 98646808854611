
.seo-landing {
  background-color: $color-off-white;
}

.seo__table {
  padding: 45px 5% 0;
  width: 100%;

  @include breakpoint(tablet-vert) {
    margin-top: -170px;
    padding: 20px 20px 40px;

    .monitor-table__heading {
      text-align: left;

      h3 {
        position: relative;
        padding-left: 20px;

        &:before {
          content: "";
          width: 6px;
          height: 10px;
          background-image: url("../images/png/list-item-arrow.png");
          background-size: contain;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -5px;
          background-position: top center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.seo-accessories {
  padding: 40px 0;

  @include breakpoint(tablet-vert) {
    padding: 70px 0;
  }

  &__heading {
    color: $color-navy-blue;
    margin-bottom: 40px;
    text-align: center;

    @include breakpoint(tablet-vert) {
      @include to-em(40px);
      margin-bottom: 70px;
    }
  }

  &__grid {
    @include breakpoint(tablet-vert) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__grid-item {
    text-align: center;
    flex-grow: 1;

    @include breakpoint(large down) {
      margin-top: 60px;

      &:first-child {
        margin-top: 0;
      }
    }

    @include breakpoint(tablet-vert) {
      width: 31.2%;
      margin-left: 3.2%;

      &:first-child {
        margin-left: 0;
      }
    }

    @include breakpoint(xlarge) {
      width: 33%;
      margin-left: 100px;
    }
  }

  &__grid-item-heading {
    color: $color-navy-blue;

    @include breakpoint(tablet-vert) {
      @include to-em(20px);
    }
  }

  &__grid-item-image {
    margin-bottom: 25px;

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  &__btn {
    margin: 24px auto 0;
    max-width: 300px;
  }
}

.rated-accessories {
  &__heading {
    span {
      background-color: $color-off-white;
    }
  }

  &__grid {
    margin-bottom: 65px;

    @include breakpoint(phone-horiz-tiny) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include breakpoint(custom-tablet) {
      flex-wrap: nowrap;
    }
  }

  &__grid-item {
    text-align: center;
    background-color: $color-white;
    color: $color-navy-blue;
    padding: 50px 10px;
    margin-top: 20px;

    @include breakpoint(phone-horiz-tiny) {
      width: 48.4%;
      margin-left: 3.2%;
      flex-grow: 1;

      &:nth-of-type(2n+1) {
        margin-left: 0;
      }
    }

    @include breakpoint(custom-tablet) {
      margin-top: 0;
      width: auto;

      &:nth-of-type(2n+1) {
        margin-left: 3.2%;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
