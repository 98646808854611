.reveal {
  &.searchModal {
    @include breakpoint(tablet-vert) {
      width: 65%;
    }

    overflow-x: hidden;
    padding: 0;
    border-radius: 6px;

    .product-search {
      background-color: $color-off-white;
      padding: 20px;

      @include breakpoint(tablet-vert) {

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e8e8e8+45,f4f4f4+45 */
        background: $color-off-white; /* Old browsers */
        //background: -moz-linear-gradient(left, $color-off-white 64%, $color-light-grey-2 64%); /* FF3.6-15 */
        //background: -webkit-linear-gradient(left, $color-off-white 64%,$color-light-grey-2 64%); /* Chrome10-25,Safari5.1-6 */
        //background: linear-gradient(to right, $color-off-white 64%,$color-light-grey-2 64%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-off-white', endColorstr='$color-light-grey-2',GradientType=1 ); /* IE6-9 */

        .container {
          //display: flex;
          //justify-content: space-between;
          //max-width: 980px;
        }
      }

      @include breakpoint(medium down) {
        padding-bottom: 80px;
      }

      @include breakpoint(xlarge) {
        padding: 20px;
      }

      .search-bar__wrapper {
        width: 100%;
        margin-bottom: 20px;

        @include breakpoint(tablet-vert) {
          margin-bottom: 0;
          max-width: 680px;
          width: 65%;

          &--full-width {
            width: 100%;
            max-width: none;
          }
        }
      }

      .search-bar__form {
        padding: 4px 4px 4px 20px;

        @include breakpoint(large down) {
          display: flex;
          align-items: center;
        }

        @include breakpoint(tablet-vert) {
          padding: 4px 4px 4px 30px;
        }
      }

      .search-bar__form-submit {
        overflow: hidden;
        height: auto;
        width: auto;
        text-indent: 0;
        background-image: none;
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;

        @include to-em(15px);
        border-radius: 3px;
        color: $color-white;
        cursor: pointer;
        display: block;
        font-weight: bold;
        padding: 12px 25px;
        text-align: center;
        text-transform: uppercase;
        transition: all 200ms;
        position: relative;
        z-index: 1;

        background: $color-brilliant-red;

        &:after {
          display: block;
          content: "";
          z-index: -1;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(to bottom, #c5466a 0%, #b20939 100%);
          opacity: 1;
          transition: all 200ms;
        }

        @include breakpoint(medium down) {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 40px);
        }

        @include breakpoint(tablet-vert) {
          &:hover {
            color: $color-white;

            &:after {
              opacity: 0;
            }
          }
        }

      }

    }

    .search-results {
      position: relative;

      &.active {
        padding: 20px 40px;
      }

      &:after {
        width: 1px;
        background: $light-gray;
        content: "";
        position: absolute;
        top: 20px;
        bottom: 20px;
        left: 50%;
      }

      h4 {
        @include to-em(17px);
        color: $color-brilliant-red;
        margin: 10px 0 20px;
      }

      &__post {
        padding-left: 40px;
        padding-right: 40px;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin: 0 0 24px;
            padding: 0;
            list-style: none;

            a {
              display: flex;
              align-items: center;
              color: $color-navy-blue;
              @include to-em(13px);

              img {
                width: 70px;
                height: 70px;
                object-fit: contain;
                border-radius: 6px;
                background: $very-light-gray;
                margin-left: 15px;
              }

              span {
                flex: 0 1 auto;
                margin-left: 15px;
              }
            }

            /*&::before {
              top: 50%;
              margin-top: -5px;
            }*/
          }
        }
      }

      &__page {
        padding-left: 40px;
        padding-right: 40px;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin: 0 0 24px;
            padding: 0;
            list-style: none;

            a {
              display: flex;
              align-items: center;
              color: $color-navy-blue;
              @include to-em(13px);

              img {
                width: 70px;
                height: 70px;
                object-fit: contain;
                border-radius: 6px;
                background: $very-light-gray;
                margin-left: 15px;
              }

              span {
                flex: 0 1 auto;
                margin-left: 15px;
              }
            }

            &::before {
              top: 50%;
              margin-top: -5px;
            }
          }
        }
      }
    }

  }
}
