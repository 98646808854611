
.contact-form {
  /*&__col {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    &--row {
      @include breakpoint(large down) {
        input {
          margin-top: 20px;
          width: 100%;

          &:first-child {
            margin-top: 0;
          }
        }
      }


      @include breakpoint(tablet-vert) {
        display: flex;

        input {
          margin-left: 20px;

          &:first-child {
            margin-left: 0;
          }
        }
      }


    }

    &--full {
      textarea,
      input {
        width: 100%;
      }
    }
  }*/

  .custom-select,
  &__textarea,
  &__input {
    @include to-em(18px);
    border: 1px solid $color-border-grey;
    padding: 20px;

    @include breakpoint(tablet-vert) {
      &--full {
        flex-grow: 2;
      }

      &--half {
        flex-grow: 1;
      }

      &--third {
        width: 40%;
      }
    }
  }

  &__textarea {
    display: block;
    height: 195px;
  }

  .custom-select {
    select {
      color: $text;
    }
  }

  .btn {
    @include breakpoint(large down) {
      margin: 0 auto;
    }

    @include breakpoint(tablet-vert) {
      width: 145px;
    }
  }
}
