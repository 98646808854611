.related-products {
  padding-bottom: 60px;

  &.u-round-section {
    /*@include breakpoint(tablet-vert) {
      margin-top: -200px;
      padding: 20px;
    }*/
  }

  &__item-grid {
    margin-top: 10px;
  }

  &__heading {
    @include to-em(30px);
    color: $color-navy-blue;
  }

  &__item {
    border-radius: 6px;
    border: 3px solid #F0F0F0;
    background: #f0f0f0;
    margin-top: 20px;
    overflow: hidden;
    text-align: center;
    transition: all 400ms;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 5px 40px 0 rgba(0,0,0,.05);
    }

    a {
      display: block;
    }

    figure {
      margin: 0;
      padding: 30px 0;
      background: #f6f6f6;
      border-radius: 4px 4px 0 0;
    }

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  &__item-heading {
    @include font-sans(medium);
    @include to-em(14px);
    color: $dark-gray;
    padding: 25px 10px;
    margin: 0;
  }
}
