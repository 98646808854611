.price {
	white-space: nowrap;
}

.shortcodeSKU {
	display: none;
}

.usd_price {
	display: inline-block;
}

.gbp_price {
	display: none;
}

.eur_price {
	display: none;
}	

.euralt_price {
	display: none;
}	

html[lang="es-MX"] {
	.usd_price {
		display: inline-block;
	}
	
	.gbp_price {
		display: none;
	}
	
	.eur_price {
		display: none;
	}	
	
	.euralt_price {
		display: none;
	}		
}

html[lang="en-GB"] {
	.usd_price {
		display: none;
	}

	.gbp_price {
		display: inline-block;
	}

	.eur_price {
		display: none;
	}	

	.euralt_price {
		display: none;
	}	
}


html[lang="en-EU"] {
	.usd_price {
		display: none;
	}

	.gbp_price {
		display: none;
	}

	.eur_price {
		display: inline-block;
	}	

	.euralt_price {
		display: none;
	}	
}

html[lang="de-DE"], html[lang="it-IT"], html[lang="es-ES"], html[lang="fr-FR"]{
	.usd_price {
		display: none;
	}

	.gbp_price {
		display: none;
	}

	.eur_price {
		display: none;
	}	

	.euralt_price {
		display: inline-block;
	}	
}

// Logo when a background is present
.masthead--bg:not(.masthead--simple):not(.masthead--support):not(.masthead--blog) .company-logo {

    background-image: url("../images/svg/hope-logo.svg");
	background-size: contain;

	/*html[lang="en-EU"] & {
      background-image: url("../images/svg/hope-logo-eu.svg");
	}*/
	html[lang="de-DE"] & {
      background-image: url("../images/svg/hope-logo-de.svg");		
	}
	html[lang="it-IT"] & {
      background-image: url("../images/svg/hope-logo-it.svg");
	}
	html[lang="es-ES"] &, html[lang="es-MX"] & {
      background-image: url("../images/svg/hope-logo-es.svg");
	}
	html[lang="fr-FR"] & {
      background-image: url("../images/svg/hope-logo-fr.svg");
	}
	/*html[lang="en-GB"] & {
      background-image: url("../images/svg/hope-logo-gb.svg");		
	}*/

}

// Logo when a background is not present
.masthead--nobg .company-logo,
.masthead--simple .company-logo,
.masthead--support .company-logo,
.masthead--blog .company-logo {

    background-image: url("../images/svg/hope-logo-color.svg");

	/*html[lang="en-EU"] & {
      background-image: url("../images/svg/hope-logo-color-eu.svg");
	}*/
	html[lang="de-DE"] & {
      background-image: url("../images/svg/hope-logo-color-de.svg");		
	}
	html[lang="it-IT"] & {
      background-image: url("../images/svg/hope-logo-color-it.svg");
	}
	html[lang="es-ES"] &, html[lang="es-MX"] & {
      background-image: url("../images/svg/hope-logo-color-es.svg");
	}
	html[lang="fr-FR"] & {
      background-image: url("../images/svg/hope-logo-color-fr.svg");
	}
	/*html[lang="en-GB"] & {
      background-image: url("../images/svg/hope-logo-color-gb.svg");		
	}*/

}
