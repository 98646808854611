body.page-template div.gform_wrapper {
	margin-top: 0;

	.top_label div.ginput_container {
		margin-top: 0;
	}

	ul.gform_fields {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		li.gfield {
			flex-basis: 100%;
			padding-right: 0;
			clear: none;
			padding-top: 0;
			padding-bottom: 0;
			max-width: unset !important;

			@include breakpoint(tablet-vert) {
				&.half {
					flex-basis: calc(50% - 10px);
				}
	
				&.third {
					flex-basis: calc(33.33% - 10px);
				}
	
				&.two-third {
					flex-basis: calc(66.66% - 10px);
				}
			}


			// Default input style
			input,select,textarea {
				padding: 20px 25px;
				min-height: 60px;
				font-size: 14px;
				line-height: 16px;
				width: 100% !important;
				border: 1px solid #666;

				&:placeholder {
					font-size: 14px;
					line-height: 16px;
					color: $dark-gray;
				}
			}

			.ginput_container_select {
				border: 1px solid #666;
				select {
					border: none;
					width: calc(100% - 25px) !important;
					color: #757575;
				}
			}



			input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
				padding: 20px 25px;
				font-size: 14px;
				line-height: 16px;
				margin-top: 0;
			}

			&.gfield_error {
				margin-bottom: 0 !important;
				background: transparent;
				border-top: none;
				border-bottom: none;

				div.ginput_container {
					margin-top: 0px;
				}

				div.ginput_container_select {
					border: 1px solid #790000; 
				}
			}
		}
	}

	.gform_footer input[type="submit"] {
		background: $color-brilliant-red;
		text-transform: uppercase;

		@include to-em(15px);
		border-radius: 3px;
		color: $color-white;
		cursor: pointer;
		display: block;
		font-weight: bold;
		padding: 12px 25px;
		text-align: center;
		transition: all 200ms;
		overflow: hidden;
		position: relative;
		z-index: 1;
		margin-bottom: 0px;

		&:after {
			display: block;
			content: "";
			z-index: -1;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: -moz-linear-gradient(top, #c5466a 0%, #b20939 100%);
			background: -webkit-linear-gradient(top, #c5466a 0%, #b20939 100%);
			background: linear-gradient(to bottom, #c5466a 0%, #b20939 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(
											startColorstr="#c5466a",
											endColorstr="#b20939",
											GradientType=0
			);

			opacity: 1;
			transition: all 200ms;
		}

		@include breakpoint(tablet-vert) {
			&:hover {
				//background: $color-brilliant-red;
				color: $color-white;

				&:after {
					opacity: 0;
				}
			}
		}

	}

	
	&.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
		max-width: unset !important;
	}


	.validation_error {
		color: #fff;
		line-height: 1.5;
		text-shadow: none;
		background: #d94f4f;
		border-color: #c92c2c;
		font-weight: normal;
		margin: 5px 0 5px;
		padding: 3px 12px;
	}

	.validation_message {
		padding-top: 8px !important;
	}
}







.global-form {
	&__col {
		margin-top: 20px;

		&:first-child {
			margin-top: 0;
		}

		&--row {
			@include breakpoint(large down) {
				input {
					margin-top: 20px;
					width: 100%;

					&:first-child {
						margin-top: 0;
					}
				}
			}

			@include breakpoint(tablet-vert) {
				display: flex;

				input {
					margin-left: 20px;

					&:first-child {
						margin-left: 0;
					}
				}
			}
		}

		&--full {
			textarea,
			input {
				width: 100%;
			}
		}

		&--radio {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__label {
		@include to-em(12px);
	}

	.custom-select,
	&__textarea,
	&__input {
		@include to-em(18px);
		border: 1px solid $color-border-grey;
		padding: 20px;

		@include breakpoint(tablet-vert) {
			&--full {
				flex-grow: 2;
			}

			&--half {
				flex-grow: 1;
			}

			&--third {
				width: 40%;
			}
		}
	}

	&__textarea {
		display: block;
		height: 195px;
	}

	.custom-select {
		margin-top: 0;

		select {
			color: $text;
		}
	}

	&__col-input {
		margin-top: 10px;

		&:first-child {
			margin-top: 0;
		}

		@include breakpoint(tablet-vert) {
			margin-top: 0;
		}
	}

	&__radio-label {
		border: 1px solid $color-light-grey-4;
		display: block;
	}

	&__radio-item {
		cursor: pointer;
		margin-left: 20px;
		margin-top: 20px;
		overflow: hidden;
		position: relative;
		text-align: center;
		width: 80px;

		input[type="radio"] {
			padding: 0;
			margin: 0;
			height: 16px;
			width: 16px;
			float: left;
			position: absolute;
			left: 0;
			opacity: 0;
		}

		&:nth-of-type(4n + 1) {
			margin-left: 0;
		}

		span {
			display: block;
		}

		&--top {
			@include font-sans(bold);
			padding: 5px 0;
		}

		&--bottom {
			@include to-em(13px);
			background-color: rgba($color-black, 0.1);
			border: 1px solid $color-white;
			padding: 3px 0;
		}

		input[type="radio"]:checked + label {
			background-color: $color-navy-blue;
			border-color: $color-navy-blue;
			color: $color-white;

			.global-form__radio-item--bottom {
				border-color: $color-navy-blue;
			}
		}
	}
}
