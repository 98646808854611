
.monitor-table {
  @include breakpoint(tablet-vert) {
    margin: 0 auto;
    max-width: 1110px;
  }

  &__top-row {
    display: none;

    @include breakpoint(tablet-vert) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 20px 0px; // was 20 40
    }
  }

  &__label {
    @include to-em(14px);
    color: $very-dark-blue;
    font-weight: bold;
    text-transform: uppercase;

    @include breakpoint(tablet-vert) {
      @include to-em(12px);
    }
  }

  &__item {
    border-top: 2px solid rgba($text, .3);
    padding: 26px 0;

    &:first-child {
      border-top: 0;
    }

    &:nth-child(2) {
      border-top: 2px solid $color-navy-blue;
    }

    @include breakpoint(tablet-vert) {
      align-items: center;
      justify-content: space-between;
      padding: 20px 0px; // was 20 40
      display: block;

      @include breakpoint(tablet-vert) {
        display: flex;
      }
    }
  }

  &__cell {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .monitor-table__label {
      //width: 54%;
    }

    .monitor-table__value {
      @include to-em(14px);
    }

    @include breakpoint(tablet-vert) {
      display: block;
      margin: 0 0px; // was 0 10
      text-align: center;

      .monitor-table__label {
        display: none;
      }

      .monitor-table__value {
        margin: 0 auto;
      }
    }
  }

  &__image {
    img {
      display: block;
      height: auto;
      width: 100%;
    }

    @include breakpoint(tablet-vert) {
      max-width: 105px;
      width: 12%;
    }
  }

  &__heading {
    h3 {
      @include font-sans(bold);
      @include to-em(16px);
      color: $color-navy-blue;
    }

    @include breakpoint(tablet-vert) {
      //width: 30%;

      h3 {
        @include font-sans(medium);
        @include to-em(18px);
      }
    }
  }

}


.product-compare__row__content {
  text-align: left;
  position: relative;

  span.content {
    display: inline-block;
    padding-top: 20px;

    flex: 1 0 40%;
  }

  .price {
    font-weight: 700;
    color: $color-brilliant-red;
  }

  @include breakpoint(tablet-vert) {
    text-align: center;

    span.content {
      display: block;
    }
  }
}