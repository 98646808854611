#burger {
  appearance: none;
  background: 0 0;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 50px;
  outline: none;
  position: relative;
  transition: ease all 450ms;
  vertical-align: middle;
  width: 50px;
  z-index: 80;

  @include breakpoint(custom-tablet) {
    display: none;

  }

  .bar,
  .bars {
    position: absolute;
  }

  .bars {
    height: 18px;
    left: 50%;
    margin-left: -10px;
    margin-top: -9px;
    top: 50%;
    transition: transform .4s;
    width: 20px;
    will-change: transform;
  }

  .bar {
    background-color: #fff;
    display: block;
    height: 2px;
    transition: transform .4s, margin .4s, opacity .4s, background .3s;
    width: 100%;

    .masthead--nobg &,
    .masthead--small &,
    .masthead--simple & {
      background-color: $dark-gray;
    }
  }

  .bar2 {
    transform: translateY(7px);
  }

  .bar3 {
    transform: translateY(14px);
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    .bar {
      //background: #b20939;
    }
  }

  .activeNav & {
    .bars {
      transform: translateY(7px);
    }

    .bar1 {
      transform: rotate(45deg);
    }

    .bar2 {
      opacity: 0;
    }

    .bar3 {
      transform: rotate(-45deg);
    }
  }
}
