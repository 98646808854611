.page-template-three-showcase {
    background-color: $very-light-gray;
}

.three-showcase {
    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 100px;
    }

    &__intro {
        color: $dark-gray;
        text-align: center;
        max-width: 800px;
        width: 90%;
        margin: 60px auto 40px;

        p {
            @include to-em(18px);
            line-height: 28px;

        }

        h2 {
            color: $color-dark-navy-blue;
        }
    }

    &__card {
        flex: 1 1;
        background-color: $white;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;

        @include breakpoint(large down) {
            flex-basis: 100%;
        }

        &__image-wrapper {
            //padding-top: 67%;
            background-color: $color-light-grey-2;
            position: relative;
            height: 255px;

            img {
                padding: 10px;
                width: 100%;
                height: 100%;
                object-fit: contain;
                opacity: 0;
                background-color: $color-light-grey-2;
                position: absolute;
                top: 0;
                left: 0;

                &:first-of-type {
                    opacity: 1;
                }
            }

            .btn {
                position: absolute;
                background-color: $white;
                color: $color-brilliant-red;
                @include to-em(10px);
                line-height: 23px;
                left: 14px;
                top: 14px;
                padding: 3.5px;
                padding-right: 7px;
                
                span {
                    color: $color-brilliant-red;
                    font-size: 17px;
                    line-height: 23px;
                }
            }
        }

        &__gallery {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            margin: 0 auto;
            gap: 6px 5px;
            margin: 10px 10px 20px;

            

            img {
                width: calc(20% - 4px); // 5 across, if possible.
                height: 42px;
                object-fit: contain;

                &.active {
                    height: 46px;
                    border: 2px solid $color-dark-navy-blue;
                }
            }
        }

        &__title {
            @include to-em(30px);
            color: $color-navy-blue;
            margin: 0 24px 15px;

        }

        &__text {
            letter-spacing: 0;
            margin: 0 24px 45px;
            @include to-em(13px);
        }

        > .btn {
            margin: auto 24px 0;
        }
    }
}