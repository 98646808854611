
.customers {
  @include breakpoint(tablet-vert) {
    .masthead__content-wrapper {
      .custom-select {
        border: 1px solid $color-white;
        background-color: $color-dark-blue;
        margin-top: 40px;

        @include breakpoint(tablet-vert) {
          margin-top: 0;
          max-width: 320px;
          width: 42%;
        }

        select {
          @include to-em(14px);
          padding: 16px 60px 16px 30px;
          color: $color-white;
          text-transform: uppercase;

        }

        &::after {
          background-image: url('../images/png/select-arrow.png');
          background-size: contain;
          height: 18px;
          right: 30px;
          width: 16px;
        }
      }
    }
  }
}

.masthead--casestudy .masthead__content {
  text-align: center;
  max-width: 48.75rem;
}

.customer-summary {
  padding: 45px 0;
  color: $color-white;
  text-align: center;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;

  &__content {
    max-width: 48.75rem;
  }

  &__cta {
    max-width: 15rem;
    margin: 0 auto;
  }
}
