div.heat-calculator {
    background: $light-gray;
    width: 400px;
    padding: 60px 45px;
    margin-left: 10px;
    @include breakpoint(medium down) {
        margin-left: 0;
        width: 100%;
        padding: 60px 40px;
    }

    .presets-factor, .presets-offset { display: none; }

    h3 {
        color: #234a71 !important;
        text-transform: uppercase;
        text-align: center;
        font-size: 13px !important;
        line-height: 17px;
    }

    p {
        color: #787878;
        font-size: 13px;
        line-height: 17px;
        text-align: center;
    }

    .heat-calculator__input {
        height: 45px;
        //text-align: center;
        position: relative;
        display: block;
        margin: 0 auto 10px;
        width: 180px;

        &--temp:after {
            content: '°C';
            color: darkslategray;
            position: absolute;
            left: calc(100% + 2px);
            top: calc(50% - 12px);
        }

        &--wattage:after {
            content: 'W';
            color: darkslategray;
            position: absolute;
            left: calc(100% + 2px);
            top: calc(50% - 12px);
        }

        input {
            border: 1px solid darkslategray;
            height: 100%;
            width: 100%;
            padding: 0 10px;
            font-size: 20px;

            &:invalid {
                border-color: #b20939;
            }

            &::placeholder {
                font-size: 13px;
            }
        }
    
    }

    .heat-calculator__radios {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        label {
            font-size: 14px;
        }

        input {
            margin-right: 10px;
        }
    }

    select {
        width: 250px;
        height: 45px;
        display: block;
        margin: 0 auto 30px;
        padding: 0 10px;
        font-size: 13px;
    }

    .reset { 
        text-align: center;
        display: none; 
        text-decoration: underline;
    }

    .calculate {
        text-align: center;
        display: block;
        width: 250px;
        margin: 0 auto;
    }

    &__results, &__error {
        display: none;
        color: #b20939;
        font-weight: 700;
        text-align: center;
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 18px;
    }

    &.submitted {
        .reset { display: block; }
        .calculate { display: none; }
        .heat-calculator__results { display: block; }
    }
}

.graph-and-calc {
    display: flex;

    @include breakpoint(medium down) {
        display: block;
    }

    img {
        margin: 0;
    };
}

/*
html[lang="es-MX"], html[lang="es-ES"] {
    div.heat-calculator .heat-calculator__input--wattage:after {
        content: 'watts';
    }
}
*/