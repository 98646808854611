.btn {
  @include to-em(15px);
  border-radius: 3px;
  color: $color-white;
  cursor: pointer;
  display: block;
  font-weight: bold;
  padding: 12px 0;
  text-align: center;
  text-transform: uppercase;
  transition: all 200ms;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &--navy-blue {
    background-color: $color-navy-blue;

    @include breakpoint(tablet-vert) {
      &:hover {
        background-color: darken($color-navy-blue, 5%);
      }
    }
  }

  &__red-gradient {
    background: $color-brilliant-red;

    &:after {
      display: block;
      content: "";
      z-index: -1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: -moz-linear-gradient(top, #c5466a 0%, #b20939 100%);
      background: -webkit-linear-gradient(top, #c5466a 0%, #b20939 100%);
      background: linear-gradient(to bottom, #c5466a 0%, #b20939 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(
                      startColorstr="#c5466a",
                      endColorstr="#b20939",
                      GradientType=0
      );

      opacity: 1;
      transition: all 200ms;
    }

    @include breakpoint(tablet-vert) {
      &:hover, &:focus {
        //background: $color-brilliant-red;
        color: $color-white;

        &:after {
          opacity: 0;
        }
      }
    }
  }

  &__invert {
    transition: ease all 0.3s;
    display: block;
    padding: 10px 0;

    &--white {
      border: 2px solid $color-white;

      @include breakpoint(tablet-vert) {
        &:hover {
          background-color: $color-white;
          color: $color-black;
        }
      }
    }

    &--blue {
      border: 2px solid $color-navy-blue;
      color: $color-navy-blue;

      @include breakpoint(tablet-vert) {
        &:hover {
          background-color: $color-navy-blue;
          color: $color-white;
        }
      }

      &.is-active {
          background-color: $color-dark-navy-blue;
          color: $color-white;        
      }
    }
  }
}
