@charset 'utf-8';


@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
//@include foundation-flex-grid;

@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
// @include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;
// @include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/

// Global
@import "global/_accessibility";
@import "global/colors";
@import "global/mixins";
@import "global/typography";
@import "global/base";
@import "global/utility";
@import "global/wp-admin";
@import "global/wp-overrides";
@import "global/global-search";
@import "global/i18n";

// Modules
// @import "modules/navigation";
// @import "modules/footer";
// @import "modules/header";
@import "modules/buttons";
@import "modules/content";
@import "modules/new-footer";
@import "modules/editor-style";
@import "modules/miniconfig";
@import "modules/flexible-content";
@import "modules/nav";
@import "modules/burger";

// Components
@import "components/buttons"; // We're not using this right now.
@import "components/cards";
@import "components/featured-image";
@import "components/dividers";
@import "components/links";
@import "components/product-story";
@import "components/breadcrumbs";
@import "components/c-accordion";
//@import "components/company-grid"; // Moved to modules/flexible-content/_company-grid and modified.
@import "components/contact-form";
@import "components/configurator";
@import "components/custom-select";
@import "components/global-form";
@import "components/config-form";
@import "components/monitor-table";
@import "components/product-accessories";
@import "components/products-block-details";
@import "components/products-block";
@import "components/search-bar";
@import "components/search-results";
@import "components/support-faq";
@import "components/sitemap";
@import "components/heat-calc";

// Pages
@import "pages/customers";
@import "pages/contact";
@import "pages/seo-landing";
@import "pages/product-support";
@import "pages/product-support-content";
@import "pages/product-category";
@import "pages/solutions-gallery";
@import "pages/blog";
@import "pages/configurator";
@import "pages/three-showcase";


// Templates
@import "templates/front";
@import "templates/kitchen-sink";

@import "slick.scss";
