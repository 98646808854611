.product-highlight,
.product-story {
  margin: 0 auto;
  max-width: 1220px;
  padding: 20px!important;
  width: 90%;

  @include breakpoint(phone-horiz-tiny) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include breakpoint(large) {
    flex-wrap: nowrap;
  }

  .highlight-list {
    width: 100%;
  }

  .grid-x {
    margin: 0 -10px;
  }

  &__item {
    @include to-em(14px);
    margin-bottom: 40px;
    text-align: center;

    @include breakpoint(phone-horiz-tiny) {
      width: 48.4%;
    }

    @include breakpoint(tablet-vert) {
      padding: 0 10px;
    }

    a {
      display: block;

      &:hover {
        img {
          //transform: scale(1.01);
          //box-shadow: 0px 5px 25px 0 rgba(0,0,0,.2);
          opacity: .9;
        }
      }
    }

    &-img {
      margin: 0;
      padding: 0;
      background: $medium-gray;
    }
  }

  &__image {
    display: block;
    height: auto;
    width: 100%;
    transition: all 400ms;

    @include breakpoint(large) {
      max-height: 324px;
    }
  }

  p {
    margin: 15px 8px;
    color: $dark-gray;
    @include to-em(14px);
  }
}
