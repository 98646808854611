.breadcrumbs {
  &--page {
    padding: 30px 0 0;
  }

  &--products {
    padding-bottom: 30px;

    @include breakpoint(tablet-vert) {
      padding: 20px 0 30px;
    }
  }

  &__nav {
    ul {
      display: flex;
      flex-wrap: wrap; // This is so long titles will wrap properly in the breadcrumb.
    }

    li {
      @include to-em(12px);
      @include font-sans(bold);
      text-transform: uppercase;
      margin-left: 12px;

      &::before {
        content: "/";
        padding-right: 12px;
      }

      &:first-child {
        margin-left: 0;

        &::before {
          display: none;
        }
      }
    }

    a {
      color: $color-navy-blue;
      white-space: nowrap; // Individual breadcrumbs shouldn't internally wrap.
    }
  }

  &__heading {
    @include to-em(40px);
    color: $color-navy-blue;
    margin-top: 20px;
  }
}
